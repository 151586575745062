import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { constant } from '../utils/constants'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { parseTimeInput } from '../services/services'

const dateInputProps = {
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 15,
    timeCaption: "Time",
    dateFormat: "h:mm aa",
    placeholderText: "--:--",
    className: "timePicker inputBox"
}

export default function EmployeeAvailability(props) {
    const { availability, setFieldValue, setFieldTouched, errors, touched } = props

    const parseTimeStringToDate = (timeStr) => {
        if (!timeStr) return null; // Handle undefined/null case
        if (timeStr instanceof Date) return timeStr; // If it's already a Date, return as-is
        if (typeof timeStr !== "string") {
            console.error("Invalid time format:", timeStr);
            return null; // Prevent errors if unexpected type
        }

        const [hours, minutes] = timeStr.split(":").map(Number);
        if (isNaN(hours) || isNaN(minutes)) return null; // Ensure valid numbers

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    };

    const DateInput = (props) => {
        const { title = "Date Input", name = "", value, isDisabled, indexObj } = props
        return (
            <div className="form-group">
                <label className="labelTxt">{title}
                    <span className="text-danger">*</span>
                </label>
                <DatePicker
                    {...dateInputProps}
                    name={name}
                    disabled={isDisabled}
                    selected={parseTimeStringToDate(value)}
                    onChange={(date) => {
                        if (date) {
                            const hours = date.getHours().toString().padStart(2, "0");
                            const minutes = date.getMinutes().toString().padStart(2, "0");
                            setFieldValue(name, `${hours}:${minutes}`);
                        }
                    }}
                    onChangeRaw={(e) => {
                        const input = e.target.value.trim();
                        if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                            const parsedDate = parseTimeInput(input);
                            if (parsedDate) {
                                const hours = parsedDate.getHours().toString().padStart(2, "0");
                                const minutes = parsedDate.getMinutes().toString().padStart(2, "0");
                                setFieldValue(name, `${hours}:${minutes}`);
                            }
                        }
                    }}
                    onBlur={() => setFieldTouched(name, true)}
                />
                <small className="text-danger requiredTxt">
                    {(touched?.availability?.[indexObj?.avail_index]?.shifts?.[indexObj?.shiftIndex]?.[indexObj.name]
                        && errors?.availability?.[indexObj?.avail_index]?.shifts?.[indexObj?.shiftIndex]?.[indexObj.name]) ?? ""}
                </small>
            </div>
        )
    }

    const handleShiftToggle = (name, value, index) => {
        const isChecked = !value;
        setFieldValue(name, isChecked);
        if (!isChecked) {
            setFieldValue(`availability[${index}].shifts[1].startTime`, "");
            setFieldValue(`availability[${index}].shifts[1].endTime`, "");
        } else {
            setFieldValue(`availability[${index}].shifts[1].startTime`, constant.SPLIT_AVAILABILITY.SECOND.START);
            setFieldValue(`availability[${index}].shifts[1].endTime`, constant.SPLIT_AVAILABILITY.SECOND.END);
        }
    }

    const handleAvailableToggle = (index, status, isSplit) => {
        const isChecked = !status;
        setFieldValue(`availability[${index}].status`, isChecked);
        if (isChecked) {
            setFieldValue(`availability[${index}].shifts[0].startTime`, constant.SPLIT_AVAILABILITY.FIRST.START);
            setFieldValue(`availability[${index}].shifts[0].endTime`, constant.SPLIT_AVAILABILITY.FIRST.END);
            setFieldValue(`availability[${index}].shifts[1].startTime`, constant.SPLIT_AVAILABILITY.SECOND.START);
            setFieldValue(`availability[${index}].shifts[1].endTime`, constant.SPLIT_AVAILABILITY.SECOND.END);
        } else {
            setFieldValue(`availability[${index}].shifts[0].startTime`, "");
            setFieldValue(`availability[${index}].shifts[0].endTime`, "");
            if (isSplit) {
                setFieldValue(`availability[${index}].shifts[1].startTime`, "");
                setFieldValue(`availability[${index}].shifts[1].endTime`, "");
            }
        }
    };


    return (
        <React.Fragment>
            <div className="userWork mb-3">
                <div className="workingDays">
                    <label className="labelTxt"> Availability <span className="text-danger">*</span>  </label>
                </div>
            </div>
            {availability.map((avail_item, avail_index) => {
                const day = avail_item.day
                const isSplit = avail_item.isSplit
                const weekDayName = constant.WEEKDAYS[day]
                const shifts = avail_item.shifts
                return (
                    <React.Fragment>
                        <Row className='mb-3'>
                            <Col md={4} className='d-flex gap-3'>
                                <div className="form-group">
                                    <label className="labelTxt">{weekDayName} </label>
                                    <div className="toggle-container py-2">
                                        <div role="button" className={`toggle-switch ${avail_item?.status ? "toggled" : ""}`} onClick={() => handleAvailableToggle(avail_index, avail_item.status, isSplit)} >
                                            <div className={`slider ${avail_item?.status ? "toggled" : ""}`}></div>
                                        </div>
                                    </div>
                                </div>
                                {avail_item?.status && <div className="d-flex align-items-start gap-3">
                                    <input className="mt-1" type="checkbox" checked={isSplit} onClick={() => handleShiftToggle(`availability[${avail_index}].isSplit`, avail_item.isSplit, avail_index)} />
                                    <label className="labelTxt mb-0">Split Shift </label>
                                </div>}
                            </Col>
                            {shifts && shifts.map((shift, shiftIndex) => {
                                if (!isSplit && shiftIndex > 0) return null
                                return (<React.Fragment>
                                    {shiftIndex !== 0 && <Col md={4} className='mb-2'></Col>}
                                    <Col md={4} className='mb-2'>
                                        <DateInput
                                            title={"Start time"}
                                            name={`availability[${avail_index}].shifts[${shiftIndex}].startTime`}
                                            value={shift.startTime}
                                            isDisabled={shiftIndex > 0 && !isSplit}
                                            indexObj={{ avail_index: avail_index, shiftIndex: shiftIndex, name: 'startTime' }}
                                        />
                                    </Col>
                                    <Col md={4} className='mb-2'>
                                        <DateInput
                                            title={"End time"}
                                            name={`availability[${avail_index}].shifts[${shiftIndex}].endTime`}
                                            value={shift.endTime}
                                            isDisabled={shiftIndex > 0 && !isSplit}
                                            indexObj={{ avail_index: avail_index, shiftIndex: shiftIndex, name: 'endTime' }}
                                        />
                                    </Col>
                                </React.Fragment>
                                )
                            })}
                        </Row>
                    </React.Fragment>

                )
            })}
        </React.Fragment >
    )
}
