import React from "react";
import OtpInput from "react-otp-input";

export default function OTPScreen(props) {
  const { onChange, value, resendOtp, handleOtp } = props

  const handleKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && !["Backspace", "Delete"].includes(event.key)) event.preventDefault();
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <OtpInput
          value={value}
          className={"justify-content-center"}
          onChange={onChange}
          numInputs={4}
          renderInput={(props) => (
            <input
              {...props}
              onKeyDown={handleKeyDown}
              aria-label="OTP input"
              inputMode="numeric"
            />
          )}
          inputStyle={{
            width: "87px",
            height: "56px",
            margin: "0 5px",
            fontSize: "24px",
            textAlign: "center",
            border: "1.5px solid #B2B2B270",
            borderRadius: "10px",
            backgroundColor: "#B2B2B215",
            marginTop: "30px",
          }}
        />
      </div>
      <div className="text-center">
        <button className="forgotpassword_btn my-3" type="button" onClick={handleOtp}>  Verify </button>
      </div>
      <p className="haveAccount text-center">
        Didn’t receive?{" "}
        <a className="innertext_ cursor-pointer resend_text" onClick={resendOtp}> Resend </a>
      </p>
    </div>
  );
}
