import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";

export default function SiteAccessModal(props) {
    const [modalShow, setModalShow] = useState(!Cookies.get("userSiteAccess"))

    const [formData, setFormData] = useState({ password: "" })
    const [formError, setFormError] = useState({ password: "" })

    const handleChange = (event) => {
        const { target: { value } } = event
        setFormData({ password: value })
        setFormError({ password: "" })
    }

    const handleSubmit = () => {
        if (!formData.password || formData.password == "") {
            setFormError({ ...formError, password: "Please enter password" })
            return
        }
        const access_password = import.meta.env.VITE_ACCESS_PASSWORD
        if (formData.password == access_password) {
            setModalShow(false)
            Cookies.set("userSiteAccess", "true", { expires: 1 / 24 });
            return;
        }
    }

    return (
        <div className={`${modalShow ? "site_access_loader":""}`}>
            <Modal show={modalShow} centered className="addModal">
                <Modal.Header>
                    <Modal.Title>Site Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="col-md-12 mb-3">
                            <div className="form-group">
                                <label className="labelTxt">Enter your password</label>
                                <input name="note" className="inputBox" value={formData?.password} onChange={handleChange} />
                            </div>
                            <small className="text-danger requiredTxt">
                                {formError.password && formError.password}
                            </small>
                        </div>

                        <div className="d-flex align-items-center gap-3">
                            <button className="greenBtn" type="button" onClick={handleSubmit}   >
                                Submit Password
                            </button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
