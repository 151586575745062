import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as images from "../assets/image";
import useRole from "../hooks/useRole";
import { constant } from "../utils/constants";
import useToggle from "../hooks/useToggle";
import { useDispatch } from "react-redux";
import { toggleSwitch } from "../redux/features/authSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const toggle = useToggle();
  console.log(toggle, "useToggle");
  const role = useRole();
  const handleToggle = () => {
    dispatch(toggleSwitch());
  };
  const navigate = useNavigate()

  return (
    <>
      <div className={`sidebar`} style={{ display: `${toggle ? "block" : ""}` }}>
        <p className="logoArea" role="button" onClick={() => navigate("/")}>
          <h5>Scheduler</h5>
          <div>
            <img src={images.calender} alt="image" className="sidebarLogo" />
          </div>
        </p>

        {role === constant.ROLE.ADMIN ? (
          <ul className="menuLists">
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/calendar" onClick={handleToggle}>
                Calendar
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/client" onClick={handleToggle}>
                Client
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/employee" onClick={handleToggle}>
                Employee
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/time-off" onClick={handleToggle}>
                Time Off
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/payout" onClick={handleToggle}>
                Payroll
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/add-timesheet" onClick={handleToggle}>
                Timesheet
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/admin/content" onClick={handleToggle}>
                Website
              </NavLink>
            </li>
          </ul>
        ) : role === constant.ROLE.EMPLOYEE ? (
          <ul className="menuLists">
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/calendar" onClick={handleToggle}>
                Calendar
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/shifts" onClick={handleToggle}>
                Schedule
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/add-timesheet" onClick={handleToggle}>
                Timesheet
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/payout" onClick={handleToggle}>
                Payout
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/employee/time-off" onClick={handleToggle}>
                Time Off
              </NavLink>
            </li>
          </ul>
        ) : (
          <ul className="menuLists">
            <li className="menu-list">
              <NavLink className="list-item" to="/calendar" onClick={handleToggle}>
                Calendar
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/schedule" onClick={handleToggle}>
                Schedule
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/request" onClick={handleToggle}>
                Request
              </NavLink>
            </li>
            <li className="menu-list">
              <NavLink className="list-item" to="/timesheet" onClick={handleToggle}>
                Timesheets
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Sidebar;
