import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import * as yup from "yup";
import * as images from "../../assets/image";
import { resetPasswordForgot, signIn } from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";
import { toastAlert } from "../../utils/SweetAlert";

const ResetPassword = () => {
  const navigate = useNavigate()
  const params = useParams()

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
    },
    validationSchema: yup.object().shape({
      newPassword: yup
        .string()
        .required()
        .trim()
        .label("New Password")
        .matches(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
          "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character."
        ),
      confirmPassword: yup
        .string()
        .required()
        .label("Confirm Password")
        .trim()
        .oneOf(
          [yup.ref("newPassword"), null],
          "New password and confirm password must match"
        ),
    }),
    onSubmit: async (values) => {
      mutation.mutate({ password: values?.confirmPassword, });
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => resetPasswordForgot(body, params.token),
    onSuccess: (resp) => {
      if (resp.data.statusCode === 200) {
        toastAlert('success', resp?.data?.message || "Success")
        navigate("/")
      }
    },
    onError: (error) => {
      const errorMesss = error.response.data.message || "Something went wrong"
      toastAlert('error', errorMesss)
      if (error.status == 401) navigate("/")
    }
  });

  useEffect(() => {
    if (!params.token || params.token == null || params.token === "")
      navigate('/')
  }, [params])

  return (
    <div className="mainOuter">
      <section className="loginOuter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="loginPage">
                <div className="loginContent">
                  <img
                    src={images.logo}
                    alt="image"
                    className="mb-4 logoImg"
                  />
                  <h2 className="heading24">Reset Password</h2>
                  <form onSubmit={handleSubmit} className="loginForm">
                    <div className="mb-3">
                      <div className="form-group position-relative">
                        <label className="labelTxt">New Password</label>
                        <input
                          className="inputBox"
                          type={values?.showNewPassword ? "text" : "password"}
                          value={values?.newPassword}
                          name="newPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter New Password"
                        />
                        <div className="eye-icon" onClick={() => setFieldValue("showNewPassword", !values?.showNewPassword)}  >
                          {!values?.showNewPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                        </div>
                      </div>
                      <small className="text-danger requiredTxt">
                        {touched?.newPassword && errors?.newPassword}
                      </small>
                    </div>
                    <div className="mb-3">
                      <div className="form-group position-relative">
                        <label className="labelTxt">Confirm Password</label>
                        <input
                          type={values?.showConfirmPassword ? "text" : "password"}
                          className="inputBox"
                          placeholder="Enter Confirm Password"
                          value={values?.confirmPassword}
                          name="confirmPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="eye-icon" onClick={() => setFieldValue("showConfirmPassword", !values?.showConfirmPassword)}  >
                          {!values?.showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                        </div>
                      </div>
                      <small className="text-danger requiredTxt">
                        {touched?.confirmPassword && errors?.confirmPassword}
                      </small>
                    </div>
                    <button className="signInBtn" type="submit" >
                      Change Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};

export default ResetPassword;
