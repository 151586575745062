import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useRef } from "react";
import { Card, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import * as images from "../../assets/image";
import EmployeeAvailability from "../../common/EmployeeAvailability";
import NavigateBack from "../../common/NavigateBack";
import { addUser, getUserDetails, updateUser } from "../../services/services";
import { constant } from "../../utils/constants";
import { noSpecialChars } from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";

const AddEmployee = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam?.get("id");

  const shiftSchema = yup.object().shape({
    startTime: yup.string().required('Start time is required'),
    endTime: yup.string()
      .required('End time is required')
      .test(
        'is-after-start',
        'End time must be after start time',
        function (endTime) {
          const { startTime } = this.parent;
          if (!startTime || !endTime) return true;
          return new Date(`1970-01-01T${endTime}`) > new Date(`1970-01-01T${startTime}`);
        }
      ),
  });


  const dateRef = useRef();
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setValues,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      hourlyRate: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      role: constant.ROLE.EMPLOYEE,
      note: "",
      password: "",
      availability: constant.WEEKDAYS.map((i, ind) => ({
        day: ind,
        status: false,
        isSplit: false,
        shifts: [{
          startTime: "",
          endTime: "",
        }]
      })),
    },
    validationSchema: yup.object().shape({
      firstName: yup
        .string()
        .required()
        .label("First name")
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "First name should contain letters only"
        ),
      lastName: yup
        .string()
        .required()
        .label("Last name")
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "Last name should contain letters only"
        ),
      email: yup.string().required().email().label("Email").trim(),
      phoneNumber: yup
        .string()
        .min(5)
        .label("Phone Number")
        .test("phone-validate", "Invalid phone number", function (value) {
          if (value?.length > 6) {
            return isValidPhoneNumber(String(value));
          } else {
            return true;
          }
        }),
      hourlyRate: yup
        .number()
        .required()
        .positive()
        .label("Hourly Rate")
        .typeError("Invalid number input"),
      availability: yup.array().of(
        yup.object().shape({
          status: yup.boolean().required(),
          isSplit: yup.boolean(),
          shifts: yup.array()
            .when("status",
              {
                is: (value) => value,
                then: () => yup.array().of(shiftSchema).test(
                  'shifts-validation',
                  'Invalid shifts configuration',
                  function (shifts) {
                    const { isSplit } = this.parent;

                    // Validate first shift
                    const firstShift = shifts[0];
                    if (!firstShift.startTime) {
                      return this.createError({
                        path: `${this.path}.0.startTime`,
                        message: 'Start time is required when status is true',
                      });
                    }
                    if (!firstShift.endTime) {
                      return this.createError({
                        path: `${this.path}.0.endTime`,
                        message: 'End time is required when status is true',
                      });
                    }

                    // Validate second shift if isSplit is true
                    if (isSplit) {
                      if (shifts.length < 2) {
                        return this.createError({
                          message: 'Second shift is required when split is true',
                        });
                      }

                      const secondShift = shifts[1];
                      if (!secondShift.startTime) {
                        return this.createError({
                          path: `${this.path}.1.startTime`,
                          message: 'Second shift start time is required when split is true',
                        });
                      }
                      if (!secondShift.endTime) {
                        return this.createError({
                          path: `${this.path}.1.endTime`,
                          message: 'Second shift end time is required when split is true',
                        });
                      }

                      // Ensure second shift starts after first shift ends
                      if (firstShift.endTime && secondShift.startTime) {
                        const firstEnd = new Date(`1970-01-01T${firstShift.endTime}`);
                        const secondStart = new Date(`1970-01-01T${secondShift.startTime}`);
                        if (secondStart <= firstEnd) {
                          return this.createError({
                            path: `${this.path}.1.startTime`,
                            message: 'Second shift must start after first shift ends',
                          });
                        }
                      }
                    }

                    return true; // All checks passed
                  }
                ),
              }
            ),



        })
      ),
      address: yup.string().required().label("Address").trim().min(2),
      city: yup.string().required().label("City").trim().min(2),
      state: yup.string().required().label("State").trim().min(2),
      country: yup.string().label("Country").trim().min(2),
      zipCode: yup
        .string()
        .required()
        .label("Zip Code")
        .trim()
        .min(2)
        .matches(/^[a-zA-Z0-9]*$/, "Invalid zip code"),
    }),
    onSubmit: (values) => {
      let dob_DATE = moment(values.dob)
      const availability = values?.availability.reduce((acc, item) => {
        const { day, isSplit, status } = item;
        if (status) {
          const sanitizedShifts = item.shifts.map(({ _id, ...shift }) => shift);
          acc.push({ day, isSplit, status, shifts: isSplit ? sanitizedShifts : [sanitizedShifts[0]], });
        }
        return acc;
      }, []);

      let body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        address: values?.address,
        city: values?.city,
        state: values?.state,
        country: values?.country,
        zipCode: values?.zipCode,
        note: values?.note,
        availability: availability,
        dob: dob_DATE.format(constant.DATE_ONLY_FORMAT),
        hourlyRate: +values?.hourlyRate,
        role: constant.ROLE.EMPLOYEE,
      };

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => (id ? updateUser(id, body) : addUser(body)),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../employee");
    },
  });

  useQuery({
    queryKey: ["user-details", id],
    queryFn: async () => {
      const resp = !!id && (await getUserDetails(id));
      let data = resp?.data?.data;

      if (data) {
        setValues({
          ...values,
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          address: data?.userAddress?.address,
          city: data?.userAddress?.city,
          state: data?.userAddress?.state,
          country: data?.userAddress?.country,
          zipCode: data?.userAddress?.zipCode,
          hourlyRate: data?.employeeDetails?.hourlyRate,
          role: constant.ROLE.EMPLOYEE,
          note: data.note,
          dob: data.dob,
          availability: values?.availability?.map((item) => {
            const dayData = data?.employeeDetails?.availability?.find(
              (i) => i.day === item.day
            );

            return dayData ? { ...item, ...dayData } : item;
          }),
        });
      }
      return true;
    },
  });

  const handleAvailableToggle = (index, status) => {
    const isChecked = !status;
    setFieldValue(`availability[${index}].status`, isChecked);
    if (isChecked) {
      setFieldValue(
        `availability[${index}].startTime`,
        constant.DEFAULT_AVAILABILITY.START
      );
      setFieldValue(
        `availability[${index}].endTime`,
        constant.DEFAULT_AVAILABILITY.END
      );
    } else {
      setFieldValue(`availability[${index}].startTime`, "");
      setFieldValue(`availability[${index}].endTime`, "");
    }
  };
  const parseTimeStringToDate = (timeStr) => {
    if (!timeStr) return null; // Handle undefined/null case

    if (timeStr instanceof Date) {
      return timeStr; // If it's already a Date, return as-is
    }

    if (typeof timeStr !== "string") {
      console.error("Invalid time format:", timeStr);
      return null; // Prevent errors if unexpected type
    }

    const [hours, minutes] = timeStr.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) return null; // Ensure valid numbers

    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <NavigateBack>{!!id ? "Edit Employee" : "Add Employee"}</NavigateBack>
          <Card border="light" bg="light" className="p-4">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={values?.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.firstName && errors?.firstName}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Last Name</label>
                      <input
                        className="inputBox"
                        type="text"
                        name="lastName"
                        value={values?.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Last Name"
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.lastName && errors?.lastName}
                      </small>
                    </div>
                  </div>
                  {/* Shift Date */}
                  <div className="col-md-6 mb-3">
                    <div className="form-group position-relative">
                      <label className="labelTxt">
                        DOB <span className="text-danger">*</span>
                      </label>
                      <img
                        src={images.calendar}
                        className="calanderIcon"
                        alt="calendarImg"
                        role="button"
                        onClick={() => dateRef?.current?.setFocus()}
                      />
                      <DatePicker
                        onChange={(date) => setFieldValue("dob", date)}
                        selected={values?.dob}
                        maxDate={new Date()}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        className="inputBox"
                        ref={dateRef}
                        onBlur={() => setFieldTouched("dob", true)}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.shiftDate && errors.shiftDate}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt" htmlFor="email">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                        name="email"
                        value={values?.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.email && errors?.email}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Contact No <span className="text-danger">*</span>
                      </label>
                      <PhoneInput
                        defaultCountry={constant.DEFAULT_COUNTRY}
                        value={values?.phoneNumber}
                        onChange={(phone) =>
                          setFieldValue("phoneNumber", phone)
                        }
                        onBlur={() => setFieldTouched("phoneNumber", true)}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.phoneNumber && errors?.phoneNumber}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Hourly Rate($)<span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Hourly Rate"
                        name="hourlyRate"
                        value={values?.hourlyRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.hourlyRate && errors?.hourlyRate}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Address <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={values?.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.address && errors?.address}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        City <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter City"
                        name="city"
                        onKeyDown={noSpecialChars}
                        value={values?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.city && errors?.city}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        State <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        onKeyDown={noSpecialChars}
                        placeholder="Enter State"
                        name="state"
                        value={values?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.state && errors?.state}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Country</label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Country"
                        name="country"
                        onKeyDown={noSpecialChars}
                        value={values?.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.country && errors?.country}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Zipcode <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        placeholder="Enter Zipcode"
                        name="zipCode"
                        value={values?.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={6}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.zipCode && errors?.zipCode}
                      </small>
                    </div>
                  </div>
                  {/* Note */}
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Note</label>
                      <textarea
                        rows={1}
                        name="note"
                        className="inputBox"
                        value={values?.note}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched.note && errors.note}
                      </small>
                    </div>
                  </div>

                  <EmployeeAvailability
                    availability={values.availability}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    setValues={setValues}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>

              <div className="staffBtn mt-4">
                <Link className="cancleBtn me-3" role="button" to={-1}>
                  Cancel
                </Link>
                <button className="addBtn " type="submit">
                  {!!id ? "Update" : "Add"}
                </button>
              </div>
            </Form>
          </Card>
        </div>
      </section>
    </>
  );
};

export default AddEmployee;
