import axios from "axios";
import { store } from "../redux/store";

/* INTERCEPTOR STARTS */
const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  "Access-Control-Allow-Origin": "*",
});


http.interceptors.request.use(
  function (config) {
    const token = store.getState()?.auth?.details?.token?.auth;
    if (token) {
      config.headers["authorization"] = `${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/* INTERCEPTOR ENDS */

/********************* AUTHENTICATION STARTS *********************/
export const signIn = async (body) => {
  return await http.post(`/users/login`, body);
};

export const logOut = async () => {
  return await http.get(`/users/logout`);
};

export const getUserProfile = async () => {
  return await http.get(`/users/user-profile`);
};

export const uploadFile = async (body) => {
  return await http.post(`/users/upload-file`, body);
};

export const updateUser = async (id, body) => {
  return await http.put(`/users/${id}`, body);
};

export const resetPassword = async (body) => {
  return await http.post(`/users/change-password`, body);
};

export const addUser = async (body) => {
  return await http.post(`/users`, body);
};

export const getUsers = async (params) => {
  return await http.get(`/users`, { params: params });
};

export const archiveUser=async(id,data)=>{
  return await http.put(`/users/archive_status_change/${id}`,data);

}

export const getUserByEvents = async (params) => {
  return await http.get(`/users/byEvent`, { params: params });
};

export const getNotification = async (params) => {
  return await http.get(`/notifications`, { params: params });
};

export const getNotificationCount = async () => {
  return await http.get(`/notifications/count`);
};
export const markAllNotification = async () => {
  return await http.get(`/notifications/readall`);
};

export const deleteUser = async (id) => {
  return await http.delete(`users/${id}`);
};

export const getUserDetails = async (id) => {
  return await http.get(`/users/user-profile?id=${id}`);
};

export const addTimeOff = async (body) => {
  return await http.post(`/leaves`, body);
};

export const getTimeOff = async (params) => {
  return await http.get(`/leaves`, { params: params });
};

export const updateLeaveStatus = async (id, body) => {
  return await http.put(`/leaves/update-status/${id}`, body);
};

export const handleDeleteLeave = async (id) => {
  return await http.delete(`/leaves/${id}`);
};

export const addEvent = async (body) => {
  return await http.post(`/events`, body);
};

export const addPastEvent = async (body) => {
  return await http.post(`/events/past-event`, body);
};

export const updateEvent = async (body = {}) => {
  const { id, isReoccurring, repeatOn, endsOn, ...rest } = body
  return await http.put(`/events/${id}`, rest);
};

export const lockEvent = async (body = {}) => {
  const { id, isReoccurring, repeatOn, endsOn, ...rest } = body
  return await http.put(`/events/lock-event/${id}`, rest);
};

export const getEvents = async (params = {}) => {
  console.log('params: ', params);
  return await http.get(`/events`, { params: params });
};
export const downloadEvents = async (params = {}) => {
  return await http.get(`/events/download-eventsheet`,  { params: params, responseType: "blob", });
};



export const deleteEvent = async (id) => {
  return await http.delete(`/events/${id}`);
};

export const updateEventStatus = async (id, status) => {
  return await http.put(`/events/update-status/${id}`, { status: status });
};

export const updateEventSteps = async (body) => {
  return await http.put(`/events/update-event-steps`,body);
};

export const assignEvents = async (params = {}) => {
  return await http.get(`/events/auto-assign`,{params:params});
};

export const syncEvents = async (params = {}) => {
  return await http.get(`/events/sync-events`);
};

export const generateCalendarLink = async (params = {}) => {
  return await http.get(`/events/ical/genrateLink`);
};

export const undoEvent = async () => {
  return await http.get(`/events/undo-changes`);
};
export const redoEvent = async () => {
  return await http.get(`/events/redo-changes`);
};

export const getPayouts = async (params = {}) => {
  return await http.get(`/payout`, { params: params });
};
export const getEventWithPayouts = async (params = {}) => {
  return await http.get(`/events/payout-events`, { params: params });
};

export const addPayout = async (body) => {
  return await http.post(`/payout`, body);
};
export const updatePayout = async (id, body) => {
  return await http.put(`/payout/${id}`, body);
};
export const softDeletePayout = async (body) => {
  return await http.put(`/events/soft_delete`, body);
};

export const downloadTimesheet = async (params = {}) => {
  return await http.get(`/payout/download-timesheet`, { params: params, responseType: "blob", });
};

export const uploadSignFile = async (body) => {
  return await http.post(`/payout/upload-file`, body);
};

// ------------ Forgot Password Flow API's ------------
export const forgotPassword = async (body) => {
  return await http.post(`/users/forgot-password`, body);
};

export const forgotPasswordVerify = async (body) => {
  return await http.post(`/users/forgot-password-verify`, body);
};

export const resetPasswordForgot = async (body, token) => {
  return await http.post(`/users/reset-password?token=${token}`, body);
};

export const resentOTP = async (body) => {
  return await http.post(`/users/resend-otp`, body);
};

export const getContentByType = async (body) => {
  return await http.get(`/webcontent?type=${body.type}`,);
};
export const addContent = async (body) => {
  return await http.post(`/webcontent`, body);
};
export const updateContent = async (body) => {
  const { id, ...rest } = body
  return await http.put(`/webcontent/${id}`, rest);
};
export const deleteContent = async (body) => {
  return await http.delete(`/webcontent`, { params: body });
};

export const uploadTeamFile = async (body) => {
  return await http.post(`/users/upload-file?type=team`, body);
};

export const uploadGalleryFile = async (body) => {
  return await http.post(`/users/upload-file?type=gallery`, body);
};


export const parseTimeInput = (input, shiftDate) => {
  let hours,
    minutes = 0;
  const isPM = /pm$/i.test(input);
  const isAM = /am$/i.test(input);

  const timeString = input.replace(/(am|pm)/i, "").trim();

  if (timeString.length === 1) {
    hours = parseInt(timeString[0], 10);
  } else if (timeString.length === 2) {
    hours = parseInt(timeString, 10);
  } else if (timeString.length === 3) {
    hours = parseInt(timeString[0], 10);
    minutes = parseInt(timeString.slice(1), 10);
  } else if (timeString.length === 4) {
    hours = parseInt(timeString.slice(0, 2), 10);
    minutes = parseInt(timeString.slice(2), 10);
  } else {
    return null;
  }

  if (isPM && hours < 12) hours += 12;
  if (isAM && hours === 12) hours = 0;

  const resultDate = new Date(shiftDate || Date.now());
  resultDate.setHours(hours, minutes, 0, 0);

  return resultDate;
};