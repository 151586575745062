import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaKey, FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as images from "../assets/image";
import useDetails from "../hooks/useDetails";
import useRole from "../hooks/useRole";
import { login } from "../redux/features/authSlice";
import { getNotificationCount, logOut } from "../services/services";
import { constant } from "../utils/constants";
import Loader from "./loader/Loader";
import { Link, NavLink } from "react-router-dom";
import { toggleSwitch } from "../redux/features/authSlice";


const Navbar = () => {
  const details = useDetails();
  const navigate = useNavigate();
  const[sidebarToggle , setSidebarToggle] = useState(false);
  const role = useRole();
  const dispatch = useDispatch();
  const location = useLocation();
  const [notificationCount, setNotificationCount] = useState(0)
  const logoutMutation = useMutation({
    mutationFn: () => logOut(),
    onSuccess: () => {
      dispatch(login({}));
    },
  });

  const handleNotificationBtn = () => {
    if(role===constant.ROLE.ADMIN||role===constant.ROLE.EMPLOYEE){
      navigate(`/${role}/notifications`);
    }
    else{
      navigate(`/notifications`);

    }
  
  };

  const handleSidebarToggle = () =>{
    dispatch(toggleSwitch())
  }


  const { data = 0, refetch } = useQuery({
    queryKey: ["notification-count", location.pathname],
    queryFn: async () => {
      let resp = await getNotificationCount();
      console.log('unreadRecords: ', resp?.data?.data?.unreadRecords);
      setNotificationCount(resp?.data?.data?.unreadRecords)
      return resp?.data?.data?.unreadRecords ?? 0;
    },
  });

  return (
    <>
      <header
        className={
          "header"
        }
      >
     
        <h3 className="headerHeading mb-0" onClick={()=>{
          handleSidebarToggle();
        }}>{/* <IoReorderThree size={30} /> */}  <i className="fa-solid fa-bars menuToggle" ></i> </h3>

        <div className="headerRight">
       <div
            className="navNotification position-relative"
            onClick={handleNotificationBtn}
          >
            <img
              src={images.notificationIcon}
              alt="image"
              className="img-fluid"
            />
            {notificationCount !== 0 && <div className="notificationBadge">{notificationCount || 0}</div>}

          </div>
          <div className="profileArea">
            <Dropdown className="profileDropdown">
              <Dropdown.Toggle id="dropdown-basic">
                <span className="img-ic">
                  <img src={
                    details?.profilePhoto
                      ? details?.profilePhoto
                      : images.profile
                  }
                  alt="Image"
                  className="me-3 profileImg"></img>
                </span>
                <label className="text-capitalize">{`${details?.firstName} ${details?.lastName}`}</label>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      role == constant.ROLE.ADMIN
                        ? "/admin/profile"
                        : role == constant.ROLE.EMPLOYEE
                        ? "/employee/profile"
                        : "/profile"
                    )
                  }
                >
                  <FaUser className="me-2" />
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      role == constant.ROLE.ADMIN
                        ? "/admin/change-password"
                        : role == constant.ROLE.EMPLOYEE
                        ? "/employee/change-password"
                        : "/change-password"
                    )
                  }
                >
                  <FaKey className="me-2" />
                  Change Password
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) => {
                    e.preventDefault();
                    logoutMutation.mutate();
                  }}
                >
                  <FiLogOut className="me-2" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
  