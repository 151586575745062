import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useRef, useState } from "react";
import { AiOutlineFileSync } from "react-icons/ai";
import { FaLink } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import Swal from "sweetalert2";
import { generateCalendarLink, getEvents, syncEvents } from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import AddRequest from "../client-request/AddRequest";
import useRole from "../../hooks/useRole";  
import {
  assignEvents,
  deleteEvent,
  updateEvent,
  updateEventStatus,
  updateEventSteps,
} from "../../services/services";

const ClientCalendar = () => {
  const calendarRef = useRef(null);
  const clickTimer = useRef(null);
  const role = useRole();
  const [currentView, setCurrentView] = useState("");

  const [show, setShow] = useState(false);
  const handleShow = () => {
    let showdata = true
    if (currentView == 'timeGridDay') {
      const currentDate = calendarRef.current.getApi().getDate();
      showdata = { date: currentDate }
    }
    setShow(showdata)
  };

  const getEventStyle = (eventObj, employeeDuration) => {
    let classname = "";
    let backgroundColor = "red";
    let isOverBooked = false;

    const startDate = new Date(eventObj?.startTime);
    const fullDate = startDate.toISOString().split("T")[0];
    const isCancelled = eventObj.status === constant.EVENT_STATUS.CANCELLED;
    const isDenied =eventObj.status===constant.EVENT_STATUS.REJECTED
    const isAccepted =eventObj.status===constant.EVENT_STATUS.ACCEPTED
    if (
      employeeDuration &&
      employeeDuration[fullDate] &&
      employeeDuration[fullDate][eventObj.employeeId] &&
      employeeDuration[fullDate][eventObj.employeeId] >=
      constant.MAX_DURATION_PER_EMPLOYEE
    ) {
      isOverBooked = true;
    }

    if (isCancelled) {
      // Cancelled events
      classname = "cancelled_event";
      backgroundColor = "blue";
    } 
    
    else if(isDenied && role==="employee"){
      classname = "denied_event";
      backgroundColor = "brown";
    }
    else if(isDenied && role==="client"){
      classname = "unassigned_event";
      backgroundColor = "red";
    }
    else if(isDenied && role==="client"){
      classname = "unassigned_event";
      backgroundColor = "red";  
    }
    else if (isAccepted || eventObj?.status === "confirmed" && eventObj?.steps !== 2) {
      classname = "scheduled_assigned_event";
      backgroundColor = "green";
    }
    else if (eventObj?.steps !== 2) {
      classname = "unassigned_event";
      backgroundColor = "red";
    }
    else if(isAccepted||eventObj?.status==="confirmed"){
      classname = "accepted_event";
      backgroundColor = "lightgrey";
    }
    else if(eventObj?.employeeId&&eventObj?.status!="confirmed"&&role==="client"){
      classname = "unassigned_event";
      backgroundColor = "red"; 
    }
    
    else if(eventObj?.status==="pending" && role==="employee"){
      classname = "unassigned_event";
      backgroundColor = "red";  
    }
    else if (!eventObj?.employeeId || eventObj?.employeeId == "") {
      // Not Assigned
      classname = "unassigned_event";
      backgroundColor = "red";
    } else if (eventObj?.employeeId && !eventObj?.sync && isOverBooked) {
      // Employee overboooked over 8 hours and the vent is not synced
      classname = "accepted_event";
      backgroundColor = "red";
      } else if (eventObj?.employeeId && !eventObj?.sync) {
      // Assigned but not synced
      classname = "notsync_event";
      backgroundColor = "yellow";
    } else if (eventObj?.employeeId && eventObj?.sync) {
      // Assigned and synced
      classname = "synced_assigned_event";
      backgroundColor = "green";
    } else if (isOverBooked) {
      classname = "accepted_event";
      backgroundColor = "red";
    }

    return { backgroundColor: backgroundColor, className: classname };
  };

  const { data, refetch } = useQuery({
    queryKey: ["event-list"],
    queryFn: async () => {
      let params = {
        sortedBy: constant.SORTED_BY.START_TIME, sortBy: constant.SORT_BY.ASC
      }
      const resp = await getEvents(params);
      let calendarEvents = [];

      if (
        resp.data &&
        resp?.data?.data?.events &&
        resp?.data?.data?.events?.length
      ) {
        let employeeDuration = {};
        calendarEvents = resp?.data?.data?.events.map((event) => {
          const clientDetails = event?.clientDetails || null;
          const employeeDetails = event?.employeeDetails || null;
          const startDate = new Date(event?.startTime); // 8 hours color shift code
          const fullDate = startDate.toISOString().split("T")[0];
          if (event.employeeId && event.employeeId !== "") {
            if (employeeDuration[fullDate]) {
              const innerObject = { ...employeeDuration[fullDate] };
              if (innerObject[event.employeeId]) {
                innerObject[event.employeeId] += event.duration;
              } else {
                innerObject[event.employeeId] = event.duration;
              }
              employeeDuration[fullDate] = innerObject;
            } else
              employeeDuration[fullDate] = {
                [event.employeeId]: event.duration,
              };
          }

          const styleProperies = getEventStyle(event, employeeDuration);
          return {
            start: new Date(event?.startTime),
            end: new Date(event?.endTime),
            title:
              role === "employee"
                ? `Client: ${event.clientId
                  ? `${clientDetails?.firstName || ""} ${clientDetails?.lastName ?? ""}`
                  : "N/A"
                }\nTime: ${moment(event?.startTime).format("LT")} - ${moment(event?.endTime).format("LT")}\nAddress: ${clientDetails?.userAddress?.address || "N/A"
                } ,\nNotes:${event?.note?.slice(0, 30) || ' N/A'}`
                : `Employee: ${(event.employeeId && (event.status === "confirmed" || event.status === "accepted" || event.status === "cancelled"))
                  ? `${employeeDetails?.firstName || ""} ${employeeDetails?.lastName ?? ""}`
                  : "N/A"
                }\nTime: ${moment(event?.startTime).format("LT")} - ${moment(event?.endTime).format("LT")},\nNotes:${event?.note?.slice(0, 30) || ' N/A'}`,
            description: event?.note,
            extendedProps: {
              ...event,
              client: clientDetails,
              employee: employeeDetails,
            },
            ...styleProperies,
          };
          
        });
      }
      return calendarEvents;
    },
  });

  const handleEventSync = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sync events to your google calendar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          syncEventsMutation.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const syncEventsMutation = useMutation({
    mutationFn: () => syncEvents(),
    onSuccess: async (resp) => {
      if (resp.data.statusCode == constant.STATUS_CODE.SUCCESS) {
        toastAlert("success", resp.data?.message);
      }
      refetch();
    },
  });



  const handleDateClick = (info) => {
    const clickedDate = new Date(info.date);
    const today = new Date();
    if (clickedDate >= today.setHours(0, 0, 0, 0)) {
      if (currentView == "timeGridDay" || currentView == "timeGridWeek") {
        if (role !== constant.ROLE.CLIENT) return;
        const today = new Date();
        if (clickedDate >= today) setShow(info);
      } else {
        const selectedDate = info.dateStr;
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.changeView("timeGridDay", selectedDate);
        }
      }
    } else console.log("Past date selected");
  };


  const handleCancel = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to cancel this event!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) cancelMutation.mutate(id);
    });
  };

  const cancelMutation = useMutation({
    mutationFn: async (id) => {
      return await updateEventStatus(id, constant.EVENT_STATUS.CANCELLED);
    },
    onSuccess: () => {
      toastAlert("success", "Event has been cancelled");
      refetch();
    },
  });

  /****************************************Delete Event***************************************/

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) deleteMutation.mutate(id);
    });
  };

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      return await deleteEvent(id);
    },
    onSuccess: () => {
      toastAlert("success", "Event has been deleted");
      refetch();
    },
  });

  const handleEventClick = (arg) => { 
    debugger;
    clearTimeout(clickTimer.current);
    clickTimer.current = setTimeout(() => {
      let id = arg?.event?.extendedProps?._id;
      let status = arg?.event?.extendedProps?.status;
  
      if (status === "accepted" || status === "confirmed") {
        // Show only the "Cancel Event" button
        Swal.fire({
          icon: "warning",
          title: "Actions",
          text: "You won't be able to revert this!",
          showDenyButton: true,
          showCloseButton: true,
          denyButtonText: "Cancel Event",
        }).then((result) => {
          if (result.isDenied) {
            handleCancel(id);
          }
        });
      } else {
        // Show both "Delete Event" and "Cancel Event" buttons
        Swal.fire({
          icon: "warning",
          title: "Actions",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          showConfirmButton: true,
          showDenyButton: true,
          confirmButtonText: "Delete Event",
          cancelButtonText: "Edit Event",
          denyButtonText: "Cancel Event",
          showCloseButton: true,
          reverseButtons: true,
          customClass: {
            actions: "swal-order",
            cancelButton: "swal-edit-btn",
            confirmButton: "swal-delete-btn",
            denyButton: "swal-cancel-btn",
          }
        }).then((result) => {
          if (result.isConfirmed) {
            handleDelete(id);
          } else if (result.isDenied) {
            handleCancel(id);
          } else if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
            handleEventDoubleClick(arg);
          }
        });
      }
    }, 300);
  };
  

  const handleEventClick1 = (arg) => {
    
    clearTimeout(clickTimer.current);
    clickTimer.current = setTimeout(() => {
      Swal.fire({
        icon: "warning",
        title: "Actions",
        text: "Please contact admin to delete or cancel this event!",
        showCloseButton: false, // Hide the close (X) button
        confirmButtonText: "OK", // Set text for the confirm button
        allowOutsideClick: false, // Prevent closing modal by clicking outside
      }).then((result) => {
        if (result.isConfirmed) {
          // The modal will close automatically when the user clicks "OK".
        }
      });
    }, 300);
  };
  
  const handleEventDoubleClick = (arg) => {
    clearTimeout(clickTimer.current);
    const clickedEventDate = new Date(arg.event.start);  
      setShow(arg);
  };

  const generateLink = useMutation({
    mutationFn: generateCalendarLink,
    onSuccess: async (resp) => {
      const link = resp.data.data
      showCalendarLinkPopup(link)
    },
  });

  const showCalendarLinkPopup = (link) => {
    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(link);
        Swal.fire({
          icon: "success",
          title: "Copied!",
          text: "The link has been copied to your clipboard.",
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (err) {
        console.error("Failed to copy:", err);
      }
    };

    Swal.fire({
      title: "Generated Calendar Link",
      html: `
        <input type="text" id="calendarLink" value="${link}" readonly 
          style="width: 100%; padding: 5px; text-align: center; border: 1px solid #ccc; border-radius: 5px;">
        <button id="copyButton" style="margin-top: 10px; padding: 5px 10px; background: #0d1227; color: white; border: none; border-radius: 5px; cursor: pointer;">
          Copy Link
        </button>
      `,
      showConfirmButton: false,
      didOpen: () => {
        document.getElementById("copyButton").addEventListener("click", copyToClipboard);
      },
    });
  };


  return (
    <div className="main-content">
      <div className="commonSearchBar my-3">
        <h4>Calendar</h4>
        <div className="endContent">
          <button
            className="greenBtn"
            type="button"
            title="Calendar Link"
            onClick={generateLink.mutate}
          >
            <FaLink size={30} />
          </button>
          <button
            className="greenBtn"
            type="button"
            title="Sync to google calendar"
            onClick={handleEventSync}
          >
            <AiOutlineFileSync size={30} />
          </button>
          {role === constant.ROLE.CLIENT && <button
            className="greenBtn calendar-btn"
            type="button"
            title="Add Event"
            onClick={handleShow}
          >
            <IoMdAdd size={35} />
          </button>}
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        datesSet={(arg) => setCurrentView(arg.view.type)}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          dayGridPlugin,
          listPlugin,
        ]}
        events={data}
        dayMaxEventRows={true}
        nowIndicator={true}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        
        eventClick={(el) => {
          const clickedEventDate = new Date(el.event.start);
          const today = new Date();
          if (clickedEventDate >= today.setHours(0, 0, 0, 0)) {
            if (role === "employee") {
              // handleEventClick(el);
            } else {
              if (
                el?.event?.extendedProps?.status === "rejected" ||
                el?.event?.extendedProps?.employeeId ==null||el?.event?.extendedProps?.status=="accepted"||el?.event?.extendedProps?.status=="confirmed"
              ) {
                handleEventClick(el);
              } else {
                handleEventClick1(el);
              }
            }
          }
          
        }}

        eventDidMount={(info) => {
          info.el.setAttribute("title", info.event.title);
          info.el.addEventListener("dblclick", () =>{   
          if((info?.event?.extendedProps?.status==="rejected"||info?.event?.extendedProps?.employeeId==null)&&role==="client"){
            handleEventDoubleClick(info)
          }
          }
          );
        }}
    
        dateClick={handleDateClick}
      />
      {show && <AddRequest show={show} setShow={setShow} refetch={refetch} />}
    </div >
  );
};

export default ClientCalendar;

