import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState, useRef } from "react";
import { Badge } from "react-bootstrap";
import { FaCheck, FaTrash, FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FcCancel } from "react-icons/fc";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import {
  getTimeOff,
  handleDeleteLeave,
  updateLeaveStatus,
} from "../../services/services";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";
import { getTableIndex } from "../../utils/function";
import * as images from "../../assets/image";

const TimeOff = () => {
  const role = useRole();
  const details = useDetails();
  const [page, setPage] = useState(constant.PAGE_NO_ONE);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [isToggled, setIsToggled] = useState(false);
  const datePickerRef = useRef();

  const handleToggle = () => {
    setPage(1);
    setIsToggled(!isToggled);
  };

  const getMonthRange = (date) => {
    const startDate = moment(date).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(date).endOf('month').format('YYYY-MM-DD');
    return `${startDate}TO${endDate}`;
  };

  const { data, refetch } = useQuery({
    queryKey: ["leave-list", page, isToggled, selectedMonth],
    queryFn: async () => {
      const range = getMonthRange(selectedMonth);
      
      let params = {
        page: page,
        sortedBy: "startTime",
        limit: constant.PAGE_LIMIT,
        futureLeaves: !isToggled,
        sortBy: constant.SORT_BY.ASC,
        range: range
      };
      
      if (role == constant.ROLE.EMPLOYEE) params.employeeId = details?._id;
      let resp = await getTimeOff(params);
      return resp?.data?.data ?? [];
    },
  });

  const leaveMutation = useMutation({
    mutationFn: ({ id, status }) => updateLeaveStatus(id, { status }),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteMutation.mutate(id);
      }
    });
  };

  const deleteMutation = useMutation({
    mutationFn: (id) => handleDeleteLeave(id),
    onSuccess: (resp) => {
      Swal.fire({
        title: "Deleted!",
        text: resp?.data?.message,
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      refetch();
    },
  });

  return (
    <section className="main-content">
      <div className="commonSearchBar mt-3">
        <h4 className="heading20">Time Off</h4>
        <div className="endContent" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div style={{ position: 'relative', width: '150px' }}>
            <div className="form-group position-relative">
              <img
                src={images.calendar}
                className="calanderIcon"
                alt="calendarImg"
                role="button"
                onClick={() => datePickerRef.current.setFocus()}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 1,
                  cursor: 'pointer'
                }}
              />
              <DatePicker
                selected={selectedMonth}
                onChange={(date) => {
                  setSelectedMonth(date);
                  setPage(1);
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="inputBox1"
                ref={datePickerRef}
              />
            </div>
          </div>
          
          <div className="toggle-container">
            <div
              className={`toggle-switch ${isToggled ? "toggled" : ""}`}
              onClick={handleToggle}
            >
              <div className={`slider ${isToggled ? "toggled" : ""}`}></div>
            </div>
            <label className="label-text">Past Time-Off</label>
          </div>
          {role !== constant.ROLE.CLIENT && (
            <Link className="greenBtn" to="../add-time-off">
              Add Time Off
            </Link>
          )}
        </div>
      </div>
      
      <div className="container-fluid p-0">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="commonTable">
                <thead className="tableHead">
                  <tr>
                    {role == constant.ROLE.ADMIN && <th>Employee</th>}
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.leaves && data?.leaves?.length > 0 ? (
                    data?.leaves?.map((item, index) => {
                      return (
                        <tr key={item._id}>
                          {role == constant.ROLE.ADMIN && (
                            <td>
                              {`${item?.employeeDetails?.firstName} ${item?.employeeDetails?.lastName}`}
                            </td>
                          )}
                          <td>
                            {`${moment(item?.startTime).format("MMMM D, YYYY [at] LT")}`}
                          </td>
                          <td>
                            {` ${moment(item?.endTime).format("MMMM D, YYYY [at] LT")}`}
                          </td>
                          <td>{item.reason}</td>
                          <td>
                            <Badge
                              bg={
                                item.status == constant.LEAVE_STATUS.PENDING
                                  ? "warning"
                                  : item?.status == constant.LEAVE_STATUS.APPROVED
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {item.status == constant.LEAVE_STATUS.PENDING
                                ? "Pending"
                                : item?.status == constant.LEAVE_STATUS.APPROVED
                                  ? "Approved"
                                  : item?.status == constant.LEAVE_STATUS.REJECTED
                                    ? "Rejected"
                                    : item?.status == constant.LEAVE_STATUS.CANCELLED
                                      ? "Cancelled"
                                      : "Cancelled"}
                            </Badge>
                          </td>
                          <td>{moment(item?.createdAt).format("ddd, MMM DD, YYYY - hh:mm A")}</td>
                          <td>
                            {moment(item?.startTime).isAfter() &&
                              role == constant.ROLE.ADMIN ? (
                              <>
                                <FaCheck
                                  size={20}
                                  role="button"
                                  className="me-2"
                                  title="Accepted"
                                  onClick={() => {
                                    if (item.status !== constant.LEAVE_STATUS.CANCELLED && item.status !== constant.LEAVE_STATUS.APPROVED) 
                                      leaveMutation.mutate({ id: item?._id, status: constant.LEAVE_STATUS.APPROVED });
                                  }}
                                />
                                <FaXmark
                                  size={20}
                                  role="button"
                                  className="me-2"
                                  title="Rejected"
                                  onClick={() => {
                                    if (item.status !== constant.LEAVE_STATUS.CANCELLED && item.status !== constant.LEAVE_STATUS.REJECTED) 
                                      leaveMutation.mutate({ id: item?._id, status: constant.LEAVE_STATUS.REJECTED });
                                  }}
                                />
                                <FaTrash
                                  size={20}
                                  role="button"
                                  title="Delete"
                                  onClick={() => handleDelete(item?._id)}
                                />
                              </>
                            ) : (
                              <React.Fragment>
                                <div className="d-flex gap-2">
                                  <FaTrash
                                    size={20}
                                    role="button"
                                    title="Delete"
                                    onClick={() => handleDelete(item?._id)}
                                  />
                                </div>
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={data?.total_pages}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TimeOff;