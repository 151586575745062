import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useRef } from "react";
import { Card, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import * as yup from "yup";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import NavigateBack from "../../common/NavigateBack";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import { addTimeOff, getUsers } from "../../services/services";
import { constant } from "../../utils/constants";
import { setTimeFromString } from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";

const AddTimeOff = () => {
  const startDateRef = useRef();
  const endDateRef = useRef();
  const navigate = useNavigate();
  const details = useDetails();
  const role = useRole();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      fullDay: false,
      startTime: "",
      endTime: "",
      reason: "",
      employeeId: "",
    },

    validationSchema: yup.object().shape({
      startDate: yup
        .date()
        .required()
        .label("Start date")
      ,

      endDate: yup
      .date()
      .required()
      .label("End date")
      .test(
        "is-after-startDate",
        "End date must be after start date",
        function (value) {
          const { startDate } = this.parent;
          if (!startDate || !value) return true; // If no startDate, validation passes
          const start = new Date(startDate).getTime();
          const end = new Date(value).getTime();
    
          return end >= start; // Allows same-day or later dates
        }
      ),
    
      employeeId: yup.object().when("role", {
        is: () => role == constant.ROLE.ADMIN,
        then: () =>
          yup.object().shape({
            value: yup.string().required().label("Employee"),
          }),
      }),

      startTime: yup
        .string()
        .required()
        .label("Start time")
        .test(
          "is-before-endTime",
          "Start time must be before end time",
          function (value) {
            const { startDate, startTime, endTime } = this.parent;
            if (!startDate || !startTime || !endTime) return true;

            const start = new Date(startDate);
            const end = new Date(startDate);
            setTimeFromString(start, startTime);
            setTimeFromString(end, endTime);

            return start < end;
          }
        ),

      endTime: yup
        .string()
        .required()
        .label("End time")
        .test(
          "is-after-startTime",
          "End time must be after start time",
          function (value) {
            const { startDate, startTime, endTime } = this.parent;
            if (!startDate || !startTime || !endTime) return true;
            const start = new Date(startDate);
            const end = new Date(startDate);
            setTimeFromString(start, startTime);
            setTimeFromString(end, endTime);

            return end > start;
          }
        ),

      reason: yup.string().required().label("Reason").trim().min(2),
    }),
    // validationSchema: yup.object().shape({
    //   startDate: yup.date().required().label("Start date"),
    //   endDate: yup.date().required().label("End Date"),
    //   employeeId: yup.object().when("role", {
    //     is: () => role == constant.ROLE.ADMIN,
    //     then: () =>
    //       yup.object().shape({
    //         value: yup.string().required().label("Employee"),
    //       }),
    //   }),
    //   startTime: yup
    //     .string()
    //     .required()
    //     .label("Start time")
    //     .test(
    //       "is-greater-than-current",
    //       "Start time should be later than the current time",
    //       function (value) {
    //         const { date } = this.parent;
    //         const startTime = new Date(date);
    //         setTimeFromString(startTime, value);
    //         return startTime >= new Date();
    //       }
    //     ),
    //   endTime: yup
    //     .string()
    //     .required()
    //     .label("End time")
    //     .test(
    //       "is-greater-than-startTime",
    //       "End time should be later than start time",
    //       function (value) {
    //         const { date, startTime } = this.parent;
    //         if (!startTime) {
    //           return true;
    //         }
    //         const start = new Date(date);
    //         const end = new Date(date);
    //         setTimeFromString(start, startTime);
    //         setTimeFromString(end, value);
    //         return end > start;
    //       }
    //     ),
    //   reason: yup.string().required().label("Reason").trim().min(2),
    // }),
    onSubmit: async (values) => {
      console.log('values: ', values);

      const startDateOnly = moment(values.startDate).format(constant.DATE_ONLY_FORMAT)
      const endDateOnly = moment(values.endDate).format(constant.DATE_ONLY_FORMAT)

      let body = {
        fullDay: values?.fullDay,
        reason: values?.reason,
        employeeId: role == constant.ROLE.EMPLOYEE ? details?._id : values?.employeeId?.value,
        startTime: moment(`${startDateOnly}T${values.startTime}`).format(constant.DATE_FORMAT),
        endTime: moment(`${endDateOnly}T${values.endTime}`).format(constant.DATE_FORMAT),
      };

      mutation.mutate(body);
    },
  });

  const employeeList = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      role: constant.ROLE.EMPLOYEE,
    };
    if (search) params.search = search;
    let resp = await getUsers(params);
    let array = (await resp?.data?.data?.users) ?? [];

    return {
      options: array.map((i) => ({
        label: `${i.firstName} ${i.lastName}`,
        value: i._id,
      })),
      hasMore: resp?.data?.data?.total_pages > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const mutation = useMutation({
    mutationFn: async (body) => addTimeOff(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../time-off");
    },
  });

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <section className="main-content">
        <div className="addclientPage ">
          <NavigateBack>Add Time Off Request</NavigateBack>
          <Card border="light" bg="light" className="p-4 w-50 mx-auto">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox ">
                <div className="row ">
                  {role && role === constant.ROLE.ADMIN && (
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label className="labelTxt">Employee</label>
                        <AsyncPaginate
                          additional={{
                            page: 1,
                          }}
                          styles={constant.REACT_SELECT_CUSTOM_STYLE}
                          debounceTimeout={500}
                          loadOptions={employeeList}
                          value={values?.employeeId}
                          onChange={(e) => setFieldValue("employeeId", e)}
                        />

                        <small className="text-danger requiredTxt">
                          {touched.employeeId && errors.employeeId?.value}
                        </small>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6 mb-3">
                    <div className="form-group position-relative">
                      <label className="labelTxt">
                        Start Date <span className="text-danger">*</span>
                      </label>
                      <img
                        src={images.calendar}
                        className="calanderIcon"
                        alt="calendarImg"
                        role="button"
                        onClick={() => startDateRef.current.setFocus()}
                      />
                      <DatePicker
                        selected={values?.startDate}
                        onChange={(date) => {
                          setFieldValue("startDate", date)
                          setFieldValue("endDate", date)
                        }}
                        onBlur={() => setFieldTouched("startDate", true)}
                        className="inputBox"
                        minDate={tomorrow}
                        ref={startDateRef}
                      />
                    </div>
                    <small className="text-danger requiredTxt">
                      {touched.startDate && errors.startDate}
                    </small>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group position-relative">
                      <label className="labelTxt">
                        End Date <span className="text-danger">*</span>
                      </label>
                      <img
                        src={images.calendar}
                        className="calanderIcon"
                        alt="calendarImg"
                        role="button"
                        onClick={() => endDateRef.current.setFocus()}
                      />
                      <DatePicker
                        selected={values?.endDate}
                        onChange={(date) => setFieldValue("endDate", date)}
                        onBlur={() => setFieldTouched("endDate", true)}
                        className="inputBox"
                        minDate={tomorrow}
                        ref={endDateRef}
                      />
                    </div>
                    <small className="text-danger requiredTxt">
                      {touched.endDate && errors.endDate}
                    </small>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Form.Check
                      type="checkbox"
                      id="custome-time"
                      label="Full day off"
                      name="fullDay"
                      value={values?.fullDay}
                      checked={values?.fullDay}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          setFieldValue("startTime", "00:00");
                          setFieldValue("endTime", "23:59");
                          setFieldTouched("startTime", false);
                          setFieldTouched("endTime", false);
                        } else {
                          setFieldValue("startTime", "");
                          setFieldTouched("startTime", false);
                          setFieldValue("endTime", "");
                          setFieldTouched("endTime", false);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Start Time <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="startTime"
                        className="timePicker inputBox"
                        disabled={values?.fullDay}
                        value={values?.startTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.startTime && errors.startTime}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        End Time <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="endTime"
                        className="timePicker inputBox"
                        disabled={values?.fullDay}
                        value={values?.endTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.endTime && errors.endTime}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows={3}
                        name="reason"
                        className="inputBox"
                        value={values?.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.reason && errors.reason}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="staffBtn mt-4">
                <button className="addBtn me-3" type="submit">
                  Add
                </button>
                <Link className="cancleBtn" role="button" to={-1}>
                  Cancel
                </Link>
              </div>
            </Form>
          </Card>
        </div>
      </section>
    </>
  );
};

export default AddTimeOff;
