import { useMutation } from '@tanstack/react-query';
import React from 'react'
import { FaTrash } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { deleteContent } from '../../../services/services';
import * as Images from '../../../assets/image';

export default function OurTeams(props) {
    const { data, refetch, handleEdit } = props

    const handleDelete = (id) => {
        console.log('id: ', id);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0d1227",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                deleteMutation.mutate({ type: "team", entryId: id });
            }
        });
    };

    const deleteMutation = useMutation({
        mutationFn: (body) => deleteContent(body),
        onSuccess: (resp) => {
            Swal.fire({
                title: "Deleted!",
                text: resp?.data?.message,
                icon: "success",
                confirmButtonColor: "#0d1227",
            });
            refetch();
        },
    });

    return (
        <div className="container-fluid p-0">
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="commonTable">
                            <thead className="tableHead">
                                <tr>
                                    <th>S.no</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.entries && data?.entries?.length > 0 ? (
                                    data?.entries?.map((entry, index) => {
                                        return (
                                            <tr key={`entry${index}`}>
                                                <td>{index + 1}</td>
                                                <td className='d-flex p-2' >
                                                    <img src={entry.image} className='mx-auto img-fluid team_img' />
                                                </td>
                                                <td>{entry.name}</td>
                                                <td>{entry.designation}</td>
                                                <td>
                                                    < img
                                                        src={Images.edit}
                                                        alt="Edit"
                                                        className="cursor editImg me-2"
                                                        onClick={() => handleEdit(entry)}
                                                    />
                                                    <FaTrash
                                                        size={20}
                                                        color='red'
                                                        title="Delete"
                                                        onClick={() => handleDelete(entry?._id)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan={5}>
                                            No Data Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    )
}
