
import { useFormik } from 'formik'
import * as yup from "yup";
import React from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query';
import { addContent, updateContent } from '../../../services/services';
import { toastAlert } from '../../../utils/SweetAlert';

export default function AddTestimonials(props) {
    const { modalDetail, closeModal } = props

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        handleSubmit,
    } = useFormik({
        initialValues: {
            type: "testimonials",
            name: modalDetail?.data?.name || "",
            description: modalDetail?.data?.description || "",
            id: modalDetail?.data?._id || null
        },
        validationSchema: yup.object().shape({
            name: yup.string().required().label("Name"),
            description: yup.string().required().label("Description")
        }),
        onSubmit: async (values) => {
            const { type, id, ...entries } = values
            const final = { id, type, entries }
            if (!id) delete final.id
            mutation.mutate(final)
        }
    })

    const mutation = useMutation({
        mutationFn: async (body) => body.id ? updateContent(body) : addContent(body),
        onSuccess: (resp) => {
            toastAlert("success", resp?.data.message);
            closeModal()
        },
    });


    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className="col-md-12 mb-3">
                    <div className="form-group">
                        <label className="labelTxt">Name</label>
                        <input
                            name="name"
                            className="inputBox"
                            value={values?.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <small className="text-danger requiredTxt">
                            {touched.name && errors.name}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 mb-3">
                    <div className="form-group">
                        <label className="labelTxt">Description</label>
                        <input
                            name="description"
                            className="inputBox"
                            value={values?.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <small className="text-danger requiredTxt">
                            {touched.description && errors.description}
                        </small>
                    </div>
                </div>

                <div className="staffBtn mt-4">
                    <button className="addBtn me-3" type="submit">
                        Submit
                    </button>
                    <Link className="cancleBtn" role="button" to={-1}>
                        Cancel
                    </Link>
                </div>
            </Form>
        </div>
    )
}
