import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NavigateBack from "../../common/NavigateBack";
import { getPayouts, getUserDetails } from "../../services/services";
import { constant } from "../../utils/constants";
import { getDaysString, getTableIndex } from "../../utils/function";
import Pagination from "../../utils/Pagination";
import { MdVerified } from "react-icons/md";

const EmployeDetails = () => {
  const [page, setPage] = useState(constant.PAGE_NO_ONE);

  const { id } = useParams();
  const { data } = useQuery({
    queryKey: ["client-details", id],
    queryFn: async () => {
      const resp = id && (await getUserDetails(id));

      return resp?.data?.data ?? {};
    },
  });

  const { data: payOutData } = useQuery({
    queryKey: ["employee-payout-details", id, page],
    queryFn: async () => {
      let params = {
        page: page,
        limit: constant.PAGE_LIMIT,
        employeeId: id,
      };
      const resp = id && (await getPayouts(params));
      return resp?.data?.data ?? {};
    },
  });

  return (
    <>
      <section className="main-content">
        <NavigateBack>Employee Details</NavigateBack>
        <Card border="light" bg="light" className="p-4">
          <div className="clientDetails">
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Employee Name</h6>
              <span className="heading16 ps-2 text-capitalize">{`${data?.firstName} ${data?.lastName}`}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Date of birth</h6>
              <span className="heading16 ps-2 text-capitalize">{data?.dob ? `${moment(data?.dob).format("ll")}` : "Not set"}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Email</h6>
              <span className="heading16 ps-2">{data?.email}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Phone Number</h6>
              <span className="heading16 ps-2">{data?.phoneNumber}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Hourly Rate</h6>
              <span className="heading16 ps-2">
                ${data?.employeeDetails?.hourlyRate}
              </span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Address</h6>
              <span className="heading16 ps-2">
                {`${data?.userAddress?.address} ${data?.userAddress?.city} ${data?.userAddress?.state} ${data?.userAddress?.country} (${data?.userAddress?.zipCode})`}
              </span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Note</h6>
              <span className="heading16 ps-2">
                {`${data?.note || "Not set"}`}
              </span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Created At</h6>
              <span className="heading16 ps-2">
                {moment(data?.createdAt).format("lll")}
              </span>
            </div>
            <div className="userWork">
              <h6 className="heading18 mb-0">Availability</h6>
              <div className="ps-4">
                {data?.employeeDetails?.availability?.map((item, index) => {
                  return (
                    <div className="row mt-4" key={`employee_availability_${index}`}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <p className="mb-0"> {getDaysString([item?.day])}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        {item.shifts && item.shifts.length > 0 ? item.shifts.map((shift, shift_index) => (
                          <React.Fragment key={`day_${item.day}_${shift_index}`}>
                            <div className="form-group d-flex gap-3 mb-2">
                              <p className="mb-0">
                                {shift?.startTime ? moment(shift?.startTime, "HH:mm")?.format("LT") : "Not set"}
                                {" to "}
                                {shift?.endTime ? moment(shift?.endTime, "HH:mm")?.format("LT") : "Not set"}
                              </p>
                              <MdVerified
                                color={item.status ? "green" : "red"}
                                style={{ marginLeft: "8px", fontSize: "20px" }}
                              />
                            </div>
                          </React.Fragment>
                        )) : ""}

                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <h6 className="heading20 mb-4">Payout Details</h6>
              <div className="table-responsive">
                <table className="commonTable">
                  <thead className="tableHead">
                    <tr>
                      <th>S. No</th>
                      <th>Client Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Shift Date</th>
                      <th>Check-In</th>
                      <th>Check-Out</th>
                      <th>Amount</th>
                      <th>Note</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payOutData &&
                      payOutData?.payouts &&
                      payOutData?.payouts?.length > 0 ? (
                      payOutData?.payouts?.map((item, index) => {
                        return (
                          <tr key={`payouts_${index}`}>
                            <td>{getTableIndex(page, index)}</td>
                            <td className="text-capitalize">
                              {item?.clientDetails
                                ? `${item?.clientDetails?.firstName} ${item?.clientDetails?.lastName}`
                                : "Client not avaialable"}
                            </td>
                            <td>
                              {item?.clientDetails
                                ? item?.clientDetails?.email
                                : "--"}
                            </td>
                            <td>
                              {item?.clientDetails
                                ? item?.clientDetails?.phoneNumber
                                : "--"}
                            </td>
                            <td>{moment(item?.shiftDate).format("ll")}</td>
                            <td>{moment(item?.checkIn, "HH:mm").format("hh:mm A")}</td>
                            <td>{moment(item?.checkOut, "HH:mm").format("hh:mm A")}</td>
                            <td>${item?.amount}</td>
                            <td>{item?.note || "--"}</td>
                            <td>{moment(item?.createdAt).format("lll")}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={10}>
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={data?.total_pages}
                />
              </div>
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};

export default EmployeDetails;
