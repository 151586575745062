
import { useFormik } from 'formik'
import * as yup from "yup";
import React from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query';
import { addContent, uploadGalleryFile } from '../../../services/services';
import { constant } from '../../../utils/constants';
import { toastAlert } from '../../../utils/SweetAlert';
import { FaCamera } from 'react-icons/fa6';
import * as images from "../../../assets/image";

export default function AddGallery(props) {
  const { modalDetail, closeModal } = props

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      type: "gallery",
      image: "",
      order: 1
    },
    validationSchema: yup.object().shape({
      image: yup.string().required().label("Image"),
    }),
    onSubmit: async (values) => {
      const { type, ...entries } = values
      mutation.mutate({ type, entries })
    }
  })

  const imageMutation = useMutation({
    mutationFn: (body) => uploadGalleryFile(body),
    onSuccess: (resp) => {
      setFieldValue("image", resp?.data?.data?.fileUrl);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => addContent(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data.message);
      closeModal()
    },
  });


  return (
    <div>
      <Form onSubmit={handleSubmit}>

        <div className="userLeft">
          <div className="userImg position-relative">
            <img
              src={
                values?.image ? values?.image : images.profile
              }
              alt="profile"
              width={250}
              className="profileUploadImg"
            />

            <label htmlFor="profileImage" className="cameraUpload">
              <FaCamera size={20} />
            </label>

            <input
              type="file"
              id="profileImage"
              className="d-none"
              accept="image/*"
              onClick={(e) => (e.target.value = null)}
              onChange={(e) => {
                const file = e.target.files[0];

                if (
                  file &&
                  !constant.SUPPORTED_FORMATS.includes(file.type)
                ) {
                  toastAlert(
                    "error",
                    "Unsupported file format. Please choose png, jpg, or jpeg."
                  );
                  return;
                }
                let formData = new FormData();
                formData.append("file", file);
                imageMutation.mutate(formData);
              }}
            />
          </div>
        </div>
        <div className="staffBtn mt-4">
          <button className="addBtn me-3" type="submit">
            Submit
          </button>
          <Link className="cancleBtn" role="button" to={-1}>
            Cancel
          </Link>
        </div>

      </Form>
    </div>
  )
}
