import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    details: "",
    toggle: false,
  },
  reducers: {
    login: (state, action) => {
      return {
        ...state,
        details: action.payload,
      };
    },
    toggleSwitch: (state) => {
      state.toggle = !state.toggle; // Toggle the value
    },
    updateUserDetails: (state, action) => {
      state.details = { ...state.details, ...action.payload }
    }
  },
});

export const { login, toggleSwitch, updateUserDetails } = authSlice.actions;

export default authSlice.reducer;
