import React from 'react'
import { Modal } from "react-bootstrap";
import AddTeams from './ModalScreens/AddTeams';
import AddGallery from './ModalScreens/AddGallery';
import AddTestimonials from './ModalScreens/AddTestimonials';

export default function AddContentModal(props) {
    const { modalDetail, closeModal } = props

    const renderComponent = {
        team: <AddTeams {...props} />,
        gallery: <AddGallery {...props} />,
        testimonials: <AddTestimonials {...props} />,
    }

    return (
        <Modal show={modalDetail.show} onHide={closeModal} centered className="addModal">
            <Modal.Header closeButton>
                <Modal.Title>Add {modalDetail.tab}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderComponent[modalDetail?.tab]}
            </Modal.Body>
        </Modal>
    )
}
