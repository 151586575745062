import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import * as images from "../../assets/image";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import { constant } from "../../utils/constants";
import { getDaysString } from "../../utils/function";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { getUserProfile } from "../../services/services";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../redux/features/authSlice";

const Profile = () => {
  const details = useDetails();
  const role = useRole();
  const dispatch = useDispatch()

  const { data, refetch } = useQuery({
    queryKey: ["user_details"],
    queryFn: async () => {

      let resp = await getUserProfile();
      dispatch(updateUserDetails(resp?.data?.data))
      return resp?.data?.data ?? {};
    },
  });
  return (
    <div className={"main-content"}>
      <div className="userData">
        <div className="d-flex justify-content-between profile_data_content">
          <div className="userLeft">
            <div className="userImg position-relative">
              <img
                src={
                  details?.profilePhoto ? details.profilePhoto : images.profile
                }
                alt="profile"
                width={250}
                className="profileUploadImg"
              />
            </div>
            <div className="userInfo p-3">
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">First Name</h6>
                <p className="mb-0">{details?.firstName}</p>
              </div>
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">Last Name</h6>

                <p className="mb-0">{details?.lastName}</p>
              </div>
              <div className="userName mb-3">
                <h6 className="heading16 mb-0">Email</h6>

                <p className="mb-0">{details?.email}</p>
              </div>
              {role !== constant.ROLE.ADMIN &&
                <React.Fragment>
                  <div className="userName mb-3">
                    <h6 className="heading16 mb-0">DOB</h6>
                    <p className="mb-0">{details?.dob ? `${moment(details?.dob).format("ll")}` : "Not set"}</p>
                  </div>
                </React.Fragment>}
            </div>
          </div>
          <div className="userRight">
            <Link className="greenBtn editProfile" to={"../edit-profile"} state={details}>
              Edit Profile
            </Link>
          </div>
        </div>
        {role == constant.ROLE.EMPLOYEE && (
          <div className="userWork ps-4">
            <div className="workingDays">
              <h5 className="prefferedDays">Availability</h5>
            </div>
            {details?.employeeDetails?.availability?.map((item, index) => {
              return (
                <div className="row mt-4" key={index}>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="labelTxt">Day</label>

                      <p className="mb-0"> {getDaysString([item?.day])}</p>
                    </div>
                  </div>
                  {item.shifts && item.shifts.length > 0 ? item.shifts.map((shift, shift_index) => (
                    <React.Fragment key={`day_${item.day}_${shift_index}`}>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="labelTxt">Start Time</label>
                          <p className="mb-0">{shift?.startTime ? moment(shift?.startTime)?.format("LT") : "Not set"} </p>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="labelTxt">End Time</label>
                          <p className="mb-0"> {shift?.endTime ? moment(shift?.endTime)?.format("LT") : "Not set"} </p>
                        </div>
                      </div>
                    </React.Fragment>
                  )) : ""}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
