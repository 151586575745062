
import { useFormik } from 'formik'
import * as yup from "yup";
import React from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query';
import { addContent, updateContent, uploadTeamFile } from '../../../services/services';
import { constant } from '../../../utils/constants';
import { toastAlert } from '../../../utils/SweetAlert';
import { FaCamera } from 'react-icons/fa6';
import * as images from "../../../assets/image";

export default function AddTeams(props) {
    const { modalDetail, closeModal } = props

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        handleSubmit,
    } = useFormik({
        initialValues: {
            type: "team",
            name: modalDetail?.data?.name || "",
            designation: modalDetail?.data?.designation || "",
            image: modalDetail?.data?.image || "",
            order: 1,
            id: modalDetail?.data?._id || null
        },
        validationSchema: yup.object().shape({
            name: yup.string().required().label("Name"),
            designation: yup.string().required().label("Designation")
        }),
        onSubmit: async (values) => {
            const { type, id, ...entries } = values
            const final = { id, type, entries }
            if (!id) delete final.id
            mutation.mutate(final)  
        }
    })

    const imageMutation = useMutation({
        mutationFn: (body) => uploadTeamFile(body),
        onSuccess: (resp) => {
            setFieldValue("image", resp?.data?.data?.fileUrl);
        },
    });

    const mutation = useMutation({
        mutationFn: async (body) => body.id ? updateContent(body) : addContent(body),
        onSuccess: (resp) => {
            toastAlert("success", resp?.data.message);
            closeModal()
        },
    });


    return (
        <div>
            <Form onSubmit={handleSubmit}>

                <div className="col-md-12 mb-3">
                    <div className="form-group">
                        <label className="labelTxt">Name</label>
                        <input
                            name="name"
                            className="inputBox"
                            value={values?.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <small className="text-danger requiredTxt">
                            {touched.name && errors.name}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 mb-3">
                    <div className="form-group">
                        <label className="labelTxt">Designation</label>
                        <input
                            name="designation"
                            className="inputBox"
                            value={values?.designation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <small className="text-danger requiredTxt">
                            {touched.designation && errors.designation}
                        </small>
                    </div>
                </div>

                <div className="userLeft">
                    <div className="userImg position-relative">
                        <img
                            src={
                                values?.image ? values?.image : images.profile
                            }
                            alt="profile"
                            width={250}
                            className="profileUploadImg"
                        />

                        <label htmlFor="profileImage" className="cameraUpload">
                            <FaCamera size={20} />
                        </label>

                        <input
                            type="file"
                            id="profileImage"
                            className="d-none"
                            accept="image/*"
                            onClick={(e) => (e.target.value = null)}
                            onChange={(e) => {
                                const file = e.target.files[0];

                                if (
                                    file &&
                                    !constant.SUPPORTED_FORMATS.includes(file.type)
                                ) {
                                    toastAlert(
                                        "error",
                                        "Unsupported file format. Please choose png, jpg, or jpeg."
                                    );
                                    return;
                                }
                                let formData = new FormData();
                                formData.append("file", file);
                                imageMutation.mutate(formData);
                            }}
                        />
                    </div>
                </div>
                <div className="staffBtn mt-4">
                    <button className="addBtn me-3" type="submit">
                        Submit
                    </button>
                    <Link className="cancleBtn" role="button" to={-1}>
                        Cancel
                    </Link>
                </div>

            </Form>
        </div>
    )
}
