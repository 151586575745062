import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Routes } from "react-router";
import Loader from "./common/loader/Loader";
import NotFound from "./common/notFound/NotFound";
import ScrollTop from "./common/ScrollTop";
import ChangePassword from "./pages/auth/ChangePassword";
import EditProfile from "./pages/auth/EditProfile";
import Login from "./pages/auth/Login";
import Profile from "./pages/auth/Profile";
import ClientCalendar from "./pages/calender/ClientCalendar";
import AdminRoutes from "./Routes/AdminRoutes";
import EmployeeRoutes from "./Routes/EmployeeRoutes";
import "./styles/auth.css";
import "./styles/custom.css";
import "./styles/responsive.css";
import {
  AdminAuth,
  EmployeeAuth,
  PublicAuth,
  UserAuth,
} from "./utils/ProtectedRoute";
import Request from "./pages/client-request/Request";
import Schedule from "./pages/client/Schedule";
import Notifications from "./pages/notifications/Notifications";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import ClientPayout from "./pages/payout/ClientPayout";
import SiteAccessModal from "./common/SiteAccessModal";
import PayOut from "./pages/payout/PayOut";

function App() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const HIDE_PASSWORD_ACCESS_MODAL = import.meta.env.VITE_HIDE_PASSWORD

  return (
    <>
      <ScrollTop />
      {(!HIDE_PASSWORD_ACCESS_MODAL || HIDE_PASSWORD_ACCESS_MODAL == "false") && <SiteAccessModal />}
      <Routes>
        <Route path="*" element={<NotFound />} />

        {/*********************Admin Routes*********************/}
        <Route path="*" element={<AdminAuth />}>
          <Route path="admin/*" element={<AdminRoutes />} />
        </Route>

        {/*********************Employee Routes*********************/}
        <Route path="*" element={<EmployeeAuth />}>
          <Route path="employee/*" element={<EmployeeRoutes />} />
        </Route>

        {/*********************Client Routes*********************/}
        <Route path="*" element={<UserAuth />}>
          <Route path="calendar" element={<ClientCalendar />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="request" element={<Request />} />
          <Route path="profile" element={<Profile />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="timesheet" element={<PayOut />} />        
        </Route>

        {/*********************All Public Routes*********************/}
        <Route path="*" element={<PublicAuth />}>
          <Route path="" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
        </Route>
      </Routes>
      {(!!isFetching || isMutating > 0) && <Loader />}
    </>
  );
}

export default App;
