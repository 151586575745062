import { useMutation } from '@tanstack/react-query';
import React from 'react'
import { FaTrash } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { deleteContent } from '../../../services/services';

export default function Gallery(props) {
    const { data, refetch } = props

    const handleDelete = (id) => {
        console.log('id: ', id);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0d1227",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                deleteMutation.mutate({ type: "gallery", entryId: id });
            }
        });
    };

    const deleteMutation = useMutation({
        mutationFn: (body) => deleteContent(body),
        onSuccess: (resp) => {
            Swal.fire({
                title: "Deleted!",
                text: resp?.data?.message,
                icon: "success",
                confirmButtonColor: "#0d1227",
            });
            refetch();
        },
    });
    return (
        <div className="container-fluid p-0">
            <div className="row mt-4">
                {data && data.entries && data?.entries?.length > 0 ? (
                    data?.entries?.map((entry, index) => {
                        return (
                            <div className="col-md-4" key={`gallery_${index}`}>
                                <div className='position-relative'>
                                    <img className='img-fluid' src={entry.image} alt="Entry" />
                                    <FaTrash
                                        size={20}
                                        color='red'
                                        title="Delete"
                                        className="trash-icon"
                                        onClick={() => handleDelete(entry?._id)}
                                    />
                                </div>

                            </div>
                        );
                    })
                ) : (<h6 className='text-center pt-4'>
                    No Data Found
                </h6>
                )}
            </div>
        </div>
    )
}
