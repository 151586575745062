import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as images from "../../assets/image";
import { deleteUser, getUsers } from "../../services/services";
import { constant } from "../../utils/constants";
import {
  convertToHours,
  getDaysString,
  getTableIndex,
  downloadFile,
} from "../../utils/function";
import Pagination from "../../utils/Pagination";
import { CiFilter, CiSaveDown1 } from "react-icons/ci";
import FilterModal from "../../common/FilterModal";
import { getEvents } from "../../services/services";
import { downloadEvents } from "../../services/services";
import { MdOutlineFileDownload } from "react-icons/md";
import { toastAlert } from "../../utils/SweetAlert";

const Schedule = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(constant.PAGE_NO_ONE);
  const [filterShow, setFilterShow] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteMutation.mutate(id);
      }
    });
  };
  const deleteMutation = useMutation({
    mutationFn: async (id) => deleteUser(id),
    onSuccess: () => {
      Swal.fire({
        title: "Deleted!",
        text: "User has been deleted.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      refetch();
    },
  });

  const handleSampleDownload = async () => {
    try {
      let params = { page: page, limit: constant.PAGE_LIMIT };
      if (
        Object.keys(filterData).length &&
        Object.keys(filterData).filter((item) => filterData[item]).length
      ) {
        params.range = `${filterData.start}TO${filterData.end}`;
      }
      params.sortedBy="startTime"
      params.sortBy="ASC"
      if (isToggled) {
        params.previousEvents = true;
      } else params.futureEvents = true;
      if (search) params.search = search;
      let resp = await downloadEvents(params);
      let fileName = params?.range
        ? `${params?.range}_timesheet.xlsx`
        : "timesheet.xlsx";
      downloadFile(resp?.data, fileName);
    } catch (err) {
      err.response.data.text().then(res => {
        let parsedObj = JSON.parse(res)
        toastAlert("error", parsedObj?.message);
      })
    }
  };

  const handleFilterShow = () => setFilterShow(true);
  const { data, refetch } = useQuery({
    queryKey: ["event-list", isToggled, filterData],
    queryFn: async () => {
      let params = {
        sortedBy: constant.SORTED_BY.START_TIME,
        sortBy: constant.SORT_BY.ASC,
      };
      if (isToggled) {
        params.previousEvents = true;
      } else params.futureEvents = true;
      if (
        Object.keys(filterData).length &&
        Object.keys(filterData).filter((item) => filterData[item]).length
      ) {
        params.range = `${filterData.start}TO${filterData.end}`;
      }
      const resp = await getEvents(params);
      // Return only the data from the response
      return resp.data?.data;
    },
  });

  return (
    <>
      <section className="main-content">
        <div className="commonSearchBar mt-3">
          <h4 className="heading20 mb-0">Schedule</h4>
          <div className="endContent">
            {/* <img
              src={images.Search}
              alt="searchImg"
              className="clientSearchIcon"
            /> */}
            <div className="toggle-container">
              <div
                className={`toggle-switch ${isToggled ? "toggled" : ""}`}
                onClick={handleToggle}
              >
                <div className={`slider ${isToggled ? "toggled" : ""}`}></div>
              </div>
              <label className="label-text">Past Assignment</label>
            </div>

            <div className="position-relative">
              {Object.keys(filterData).length &&
              Object.keys(filterData).filter((item) => filterData[item])
                .length ? (
                <div className="redDot" />
              ) : (
                ""
              )}
              <button
                className="greenBtn calendar-btn"
                type="button"
                title="Filter By"
                onClick={handleFilterShow}
              >
                <CiFilter size={35} />
              </button>
            </div>
            <button
              className="greenBtn calendar-btn"
              type="button"
              title="Download"
              onClick={handleSampleDownload}
            >
              <MdOutlineFileDownload size={30} />
            </button>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="commonTable">
                  <thead className="tableHead">
                    <tr>
                      <th>Date</th>
                      <th>Employee Name</th>
                      <th>Start-Time</th>
                      <th>End-Time</th>
                      <th>Phone Number</th>            
                    </tr>
                  </thead>
                  <tbody>
                    {data?.events && data?.events?.length > 0 ? (
                      data?.events?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {moment(item?.startTime).format(
                                "ddd, MMM DD, YYYY"
                              )}
                            </td>
                            <td className="text-capitalize">
                              {item?.employeeId && (item?.status === "confirmed"||item?.status === "accepted")
                                ? `${
                                    item?.employeeDetails?.firstName || "N/A"
                                  } ${item?.employeeDetails?.lastName || ""}`
                                : "N/A"}
                            </td>
                            <td>{moment(item?.startTime).format("hh:mm A")}</td>
                            <td>{moment(item?.endTime).format("hh:mm A")}</td>
                            <td className="text-nowrap">
                              {item?.employeeId && (item?.status === "confirmed"||item?.status === "accepted")
                                ? formatPhoneNumberIntl(
                                    item?.employeeDetails?.phoneNumber
                                  ) || "N/A"
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={10}>
                          No Data Found
                        </td>
                      </tr>
                     )}
                  </tbody>
                </table>
                <Pagination
                   page={page}
                  setPage={setPage}
                  totalPages={data?.total_pages}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {filterShow && (
        <FilterModal
          show={filterShow}
          setShow={setFilterShow}
          refetch={refetch}
          data={filterData}
          setData={setFilterData} 
        />
      )}
    </>
  );
};
export default Schedule;
