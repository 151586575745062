import React, { useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import { getContentByType, getEventWithPayouts } from '../../services/services';
import { useQuery } from '@tanstack/react-query';
import OurTeams from './ContentTables/OurTeams';
import Gallery from './ContentTables/Gallery';
import Testimonials from './ContentTables/Testimonials';
import AddContentModal from './AddContentModal';

export default function WebsiteContent(props) {
    const tabs = ['team', 'gallery', 'testimonials']
    const [activeTab, setActiveTab] = useState('team')
    const [modalDetail, setModalDetail] = useState({ show: false, tab: null, data: null })

    const { data, refetch } = useQuery({
        queryKey: ["payout-list", activeTab],
        queryFn: async () => {
            const resp = await getContentByType({ type: activeTab });
            return resp?.data?.data || {};
        },
    });
    
    const handleEdit = (data) => {
        setModalDetail({ show: true, tab: activeTab, data })
    }

    const tableProps = { data: data, refetch, handleEdit: handleEdit }
    const renderComponent = {
        team: <OurTeams {...tableProps} />,
        gallery: <Gallery {...tableProps} />,
        testimonials: <Testimonials {...tableProps} />,
    }

    const handleShow = () => setModalDetail({ show: true, tab: activeTab })
    const handleClose = () => {
        setModalDetail({ show: false, tab: null, data: null })
        refetch()
    }

    let modalProps = { modalDetail: modalDetail, closeModal: handleClose, }
    return (
        <section className="main-content">
            <div className="commonSearchBar mt-3">
                <h4 className="heading20">Website Content</h4>
                <div className="endContent">
                    <button
                        className="greenBtn calendar-btn"
                        type="button"
                        title="Add Event"
                        onClick={handleShow}
                    >
                        <IoMdAdd size={35} />
                    </button>

                </div>
            </div>
            <div className='d-flex gap-3'>
                {tabs.map((tab, tab_index) => {
                    return <React.Fragment key={`toggle_${tab}_${tab_index}`}>
                        <button className={`calendar-btn web_content_tabs ${tab == activeTab ? "active_tab" : ""}`} type="button" onClick={() => setActiveTab(tab)}>
                            {tab.toUpperCase()}
                        </button>
                    </React.Fragment>
                })}
            </div>
            {renderComponent[activeTab]}

            {modalDetail.show && <AddContentModal {...modalProps} />}
        </section>
    )
}
