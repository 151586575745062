import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCamera } from "react-icons/fa6";
import * as yup from "yup";
import * as images from "../../assets/image";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import { updateUser, uploadFile } from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import { useDispatch } from "react-redux";
import { login } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import { getDaysString, setTimeFromString } from "../../utils/function";
import moment from "moment";
import EmployeeAvailability from "../../common/EmployeeAvailability";

const EditProfile = () => {
  const role = useRole();
  const details = useDetails();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateRef = useRef();

  const shiftSchema = yup.object().shape({
    startTime: yup.string().required('Start time is required'),
    endTime: yup.string()
      .required('End time is required')
      .test(
        'is-after-start',
        'End time must be after start time',
        function (endTime) {
          const { startTime } = this.parent;
          if (!startTime || !endTime) return true;
          return new Date(`1970-01-01T${endTime}`) > new Date(`1970-01-01T${startTime}`);
        }
      ),
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      profilePhoto: "",
      availability: constant.WEEKDAYS.map((i, ind) => ({
        day: ind,
        status: false,
        isSplit: false,
        shifts: [{
          startTime: "",
          endTime: "",
        }]
      })),
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required().label("First Name").trim(),
      lastName: yup.string().required().label("Last Name").trim(),
      availability: yup.array().when("role", {
        is: () => role == constant.ROLE.EMPLOYEE,
        then: () =>
          yup.array().of(
            yup.object().shape({
              status: yup.boolean().required(),
              isSplit: yup.boolean(),
              shifts: yup.array()
                .when("status",
                  {
                    is: (value) => value,
                    then: () => yup.array().of(shiftSchema).test(
                      'shifts-validation',
                      'Invalid shifts configuration',
                      function (shifts) {
                        const { isSplit } = this.parent;

                        // Validate first shift
                        const firstShift = shifts[0];
                        if (!firstShift.startTime) {
                          return this.createError({
                            path: `${this.path}.0.startTime`,
                            message: 'Start time is required when status is true',
                          });
                        }
                        if (!firstShift.endTime) {
                          return this.createError({
                            path: `${this.path}.0.endTime`,
                            message: 'End time is required when status is true',
                          });
                        }

                        // Validate second shift if isSplit is true
                        if (isSplit) {
                          if (shifts.length < 2) {
                            return this.createError({
                              message: 'Second shift is required when split is true',
                            });
                          }

                          const secondShift = shifts[1];
                          if (!secondShift.startTime) {
                            return this.createError({
                              path: `${this.path}.1.startTime`,
                              message: 'Second shift start time is required when split is true',
                            });
                          }
                          if (!secondShift.endTime) {
                            return this.createError({
                              path: `${this.path}.1.endTime`,
                              message: 'Second shift end time is required when split is true',
                            });
                          }

                          // Ensure second shift starts after first shift ends
                          if (firstShift.endTime && secondShift.startTime) {
                            const firstEnd = new Date(`1970-01-01T${firstShift.endTime}`);
                            const secondStart = new Date(`1970-01-01T${secondShift.startTime}`);
                            if (secondStart <= firstEnd) {
                              return this.createError({
                                path: `${this.path}.1.startTime`,
                                message: 'Second shift must start after first shift ends',
                              });
                            }
                          }
                        }

                        return true; // All checks passed
                      }
                    ),
                  }
                ),



            })
          ),
      }),
    }),
    onSubmit: (values) => {
      let dob_DATE = moment(values?.dob)
      let body = {
        ...values,
        role: details?.role,
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
        dob: dob_DATE.format(constant.DATE_ONLY_FORMAT)
      };

      if (values?.profilePhoto == "" || !values?.profilePhoto) {
        delete body.profilePhoto;
      }

      /*************************For Admin*****************/
      if (role == constant.ROLE.ADMIN) {
        // delete body.email;
        delete body.availability;
        delete body.dob;
        delete body.authorizedEmployees;
      }

      /*************************For Employee*****************/                                                                                                                                                                                          
      if (role == constant.ROLE.EMPLOYEE) {
        delete body.workingDays;
        delete body.authorizedEmployees;
        const availability = values?.availability.reduce((acc, item) => {
          const { day, isSplit, status } = item;
          if (status) {
            const sanitizedShifts = item.shifts.map(({ _id, ...shift }) => shift);
            acc.push({ day, isSplit, status, shifts: isSplit ? sanitizedShifts : [sanitizedShifts[0]], });
          }
          return acc;
        }, []);
        body.availability = availability
      }

      /*************************For Client*****************/
      if (role == constant.ROLE.CLIENT) {
        delete body.availability;
      } 

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => updateUser(details?._id, body),
    onSuccess: async (resp) => {
      let data = {
        ...details,
        email: values.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        profilePhoto: values?.profilePhoto,
        dob: values.dob,
      };
      if (role == constant.ROLE.EMPLOYEE) {
        data.employeeDetails = {
          ...details?.employeeDetails,
          availability: values?.availability?.filter((i) => i.status),
        };
      }
      dispatch(login(data));
      toastAlert("success", resp?.data?.message);
      navigate(-1);
    },
  });

  useEffect(() => {
    if (details) {
      setValues({
        firstName: details?.firstName,
        lastName: details?.lastName,
        email:details?.email,
        profilePhoto: details?.profilePhoto,
        dob: details?.dob,
        availability: values?.availability?.map((item) => {
          const dayData = details?.employeeDetails?.availability?.find(
            (i) => i.day === item.day
          );
          return dayData ? { ...item, ...dayData } : item;
        }),
        authorizedEmployees: details?.authorizedEmployees,
      });
    }
  }, [details]);

  const imageMutation = useMutation({
    mutationFn: (body) => uploadFile(body),
    onSuccess: (resp) => {
      setFieldValue("profilePhoto", resp?.data?.data?.fileUrl);
    },
  });

  return (
    <div className={"main-content"}>
      <div className="userData">
        <Row>
          <Col md={4}>
            <div className="userLeft">
              <div className="userImg position-relative">
                <img
                  src={
                    values?.profilePhoto ? values?.profilePhoto : images.profile
                  }
                  alt="profile"
                  width={250}
                  className="profileUploadImg"
                />

                <label htmlFor="profileImage" className="cameraUpload">
                  <FaCamera size={20} />
                </label>

                <input
                  type="file"
                  id="profileImage"
                  className="d-none"
                  accept="image/*"
                  onClick={(e) => (e.target.value = null)}
                  onChange={(e) => {
                    const file = e.target.files[0];

                    if (
                      file &&
                      !constant.SUPPORTED_FORMATS.includes(file.type)
                    ) {
                      toastAlert(
                        "error",
                        "Unsupported file format. Please choose png, jpg, or jpeg."
                      );
                      return;
                    }
                    let formData = new FormData();
                    formData.append("file", file);
                    imageMutation.mutate(formData);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={8}>
            <Row className="mt-4">
              <Col md={8} className="mb-2">
                <label className="heading16">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={values?.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
                <small className="text-danger requiredTxt">
                  {touched?.firstName && errors?.firstName}
                </small>
              </Col>
              <Col md={8} className="mb-2">
                <label className="heading16">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={values?.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
                <small className="text-danger requiredTxt">
                  {touched?.lastName && errors?.lastName}
                </small>
              </Col>
              {role !== constant.ROLE.ADMIN && <Col md={8} className="mb-2">
                <div className="form-group position-relative">
                  <label className="heading16">
                    DOB
                  </label>
                  <img
                    src={images.calendar}
                    className="calanderIcon_profile"
                    alt="calendarImg"
                    role="button"
                    onClick={() => dateRef?.current?.setFocus()}
                  />
                  <DatePicker
                    onChange={(date) => setFieldValue("dob", date)}
                    selected={values?.dob}
                    maxDate={new Date()}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    className="form-control"
                    ref={dateRef}
                    onBlur={() => setFieldTouched("dob", true)}
                  />
                  <small className="text-danger requiredTxt">
                    {touched.dob && errors.dob}
                  </small>
                </div>
              </Col>}
              <Col md={8} className="mb-2">
                <label className="heading16 mb-0">Email</label>
                <input
                  type="email"
                  name="email"
                  value={values?.email}
                  // disabled={role == constant.ROLE.ADMIN}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {role == constant.ROLE.EMPLOYEE && (
          <EmployeeAvailability
            availability={values.availability}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setValues={setValues}
            errors={errors}
            touched={touched}
          />
        )}

        <div className="staffBtn mt-4">
          <button className="addBtn me-3" type="submit" onClick={handleSubmit}>
            Save
          </button>
          <button
            className="cancleBtn"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
