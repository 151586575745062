import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as images from "../../assets/image";
import { deleteUser, getUsers, archiveUser } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { FaFolder } from "react-icons/fa";
import { CiFilter } from "react-icons/ci";
import FilterModal from "../../common/FilterModal";

const Client = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(constant.PAGE_NO_ONE);
  const [filterShow, setFilterShow] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [isToggled, setIsToggled] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["client-list", page, filterData, isToggled],
    queryFn: async () => {
      let params = {
        page: page,
        limit: constant.PAGE_LIMIT,
        role: constant.ROLE.CLIENT,
        sortedBy: constant.SORTED_BY.FIRST_NAME,
        sortBy: constant.SORT_BY.ASC,
      };
      if(isToggled){
        params.archivedUsers=true
      }
      if (
        Object.keys(filterData).length &&
        Object.keys(filterData).filter((item) => filterData[item]).length
      ) {
        params.range = `${filterData.start}TO${filterData.end}`;
      }
      if (search) params.search = search;
      let resp = await getUsers(params);
      return resp?.data?.data ?? {};
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id) => deleteUser(id),
    onSuccess: () => {
      Swal.fire({
        title: "Deleted!",
        text: "User has been deleted.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      refetch();
    },
  });

  const archiveMutation = useMutation({
    mutationFn: async (id) => {
      // Get the current archived status of the user
      const user = data?.users?.find(user => user._id === id);
      const isCurrentlyArchived = user?.isArchived || false;
      
      // Toggle the archive status
      return await archiveUser(id, { archive: !isCurrentlyArchived });
    },
    onSuccess: () => {
      Swal.fire({
        title: "Success!",
        text: "User archive status updated.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      refetch();
    },
    onError: (error) => {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to update archive status",
        icon: "error",
        confirmButtonColor: "#0d1227",
      });
    }
  });

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) deleteMutation.mutate(id);
    });
  };

  const handleArchive = (id) => {
    const user = data?.users?.find(user => user._id === id);
    const isCurrentlyArchived = user?.isArchived || false;
    
    Swal.fire({
      title: "Are you sure?",
      text: `This client will be ${isCurrentlyArchived ? "unarchived" : "archived"}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${isCurrentlyArchived ? "unarchive" : "archive"} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        archiveMutation.mutate(id);
      }
    });
  };

  return (
    <>
      <section className="main-content">
        <div className="commonSearchBar mt-3">
          <h4 className="heading20">Client Management</h4>
          <div className="endContent clientManegement">
            <div className="toggle-container">
              <div
                className={`toggle-switch ${isToggled ? "toggled" : ""}`}
                onClick={() => setIsToggled(!isToggled)}
              >
                <div className={`slider ${isToggled ? "toggled" : ""}`}></div>
              </div>
              <label className="label-text">Show Archived</label>
            </div>
            <div className="clientSearch">
              <img
                src={images.Search}
                alt="searchImg"
                className="clientSearchIcon"
              />
              <input
                type="text"
                placeholder="Type and press enter to search."
                className="clientSearchInput"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) =>
                  e.key == "Enter" && search.trim() !== "" && refetch()
                }
                onKeyUp={(e) =>
                  e.target.value == "" && e.key == "Backspace" && refetch()
                }
              />
            </div>
            <div className="position-relative">
              {Object.keys(filterData).length &&
              Object.keys(filterData).filter((item) => filterData[item]).length ? (
                <div className="redDot" />
              ) : (
                ""
              )}
              <button
                className="greenBtn calendar-btn"
                type="button"
                title="Filter By"
                onClick={() => setFilterShow(true)}
              >
                <CiFilter size={35} />
              </button>
            </div>
            <Link className="greenBtn" to="../client/add">
              Add Client
            </Link>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="commonTable">
                  <thead className="tableHead">
                    <tr>
                      <th>Client Name</th>
                      <th>DOB</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Address</th>
                      <th>Authorized Employees</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.users && data?.users?.length > 0 ? (
                      data?.users?.map((item, index) => {
                        const isArchived = item?.isArchived || false;
                        return (
                          <tr key={`user_${index}`}>
                            <td className="text-capitalize">{`${item?.firstName} ${item?.lastName}`}</td>
                            <td className="text-capitalize">
                              {item?.dob
                                ? `${moment(item?.dob).format("ll")}`
                                : "Not set"}
                            </td>
                            <td>{item?.email}</td>
                            <td className="text-nowrap ">
                              {formatPhoneNumberIntl(item?.phoneNumber)}
                            </td>
                            <td>{`${item?.userAddress?.address} ${item?.userAddress?.city} ${item?.userAddress?.state} ${item?.userAddress?.country} (${item?.userAddress?.zipCode})`}</td>
                            <td>
                              {item?.authorizedDetails?.length
                                ? item?.authorizedDetails
                                    ?.map((i) => `${i.firstName}  ${i.lastName}`)
                                    ?.join(", ")
                                : "N/A"}
                            </td>
                            <td>
                              {moment(item?.createdAt).format(
                                "ddd, MMM DD, YYYY - hh:mm A"
                              )}
                            </td>
                            <td>
                              <div className="action-buttons">
                                <Link to={`../client/${item?._id}`}>
                                  <img
                                    src={images.eye}
                                    className="me-2 editImg"
                                    alt="img"
                                    role="button"
                                  />
                                </Link>
                                <Link to={`../client/add?id=${item?._id}`}>
                                  <img
                                    src={images.edit}
                                    className="ms-2 editImg"
                                    alt="img"
                                    role="button"
                                  />
                                </Link>
                                <FaFolder
                                  className="ms-2 editImg archive-icon"
                                  onClick={() => handleArchive(item?._id)}
                                  style={{
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                    color: isArchived ? "grey" : "black",
                                  }}
                                  title={isArchived ? "Unarchive" : "Archive"}
                                />
                                <img
                                  src={images.deleteImg}
                                  className="ms-2 editImg"
                                  alt="img"
                                  role="button"
                                  onClick={() => handleDelete(item?._id)}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={10}>
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={data?.total_pages}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {filterShow && (
        <FilterModal
          show={filterShow}
          setShow={setFilterShow}
          refetch={refetch}
          data={filterData}
          setData={setFilterData}
        />
      )}
    </>
  );
};

export default Client;