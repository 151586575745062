import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import * as images from "../../assets/image";
import { forgotPassword, forgotPasswordVerify, resentOTP } from "../../services/services";
import { useNavigate } from "react-router-dom";
import OTPScreen from "../../common/OtpScreen";
import { toast } from "react-toastify";
import { toastAlert } from "../../utils/SweetAlert";

const ForgotPassword = () => {
  const navigate = useNavigate()

  const [verifyShow, setVerifyShow] = useState(false)
  const [otp, setOTP] = useState("")

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { email: "", },
    validationSchema: yup.object().shape({ email: yup.string().required().email().label("Email"), }),
    onSubmit: async (values) => mutaiton.mutate({ email: values?.email?.trim() }),
  });

  const mutaiton = useMutation({
    mutationFn: (body) => forgotPassword(body),
    onSuccess: () => setVerifyShow(true)
  });

  const handleResendOTP = () => resendOTPMutation.mutate({ email: values?.email?.trim(), type: 'forgot' })

  const verifyOTP = () => {
    if (!otp) {
      toastAlert("error", "Please enter OTP");
      return;
    } else if (otp && otp.length !== 4) {
      toastAlert("error", "OTP must be 4 digits long");
      return;
    }
    verifyOTPMutation.mutate({ email: values?.email?.trim(), otp })
  }

  const otpProps = {
    value: otp,
    onChange: setOTP,
    handleOtp: verifyOTP,
    resendOtp: handleResendOTP
  }

  const verifyOTPMutation = useMutation({
    mutationFn: (body) => forgotPasswordVerify(body),
    onSuccess: (resp) => {
      const token = resp?.data?.data?.token || null
      if (!token) {
        toast.error("Token not found")
        return;
      }
      navigate(`/reset-password/${token}`)
    },
  });

  const resendOTPMutation = useMutation({
    mutationFn: (body) => resentOTP(body),
    onSuccess: (resp) => toastAlert("success", resp.data.message)
  });

  return (
    <div className="mainOuter">
      <section className="loginOuter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="loginPage">
                <div className="loginContent">
                  <img src={images.logo} alt="image" className="mb-4 logoImg" />
                  <h2 className="heading24">{verifyShow ? " Verify OTP " : "Forgot Password"}</h2>
                  <form onSubmit={handleSubmit} className="loginForm">
                    {verifyShow ? <OTPScreen {...otpProps} /> :
                      <React.Fragment>
                        <div className="form-group mb-2">
                          <label className="heading16 mb-1">
                            Email<span className="text-danger">*</span>
                          </label>
                          <input
                            className="inputBox"
                            type="text"
                            placeholder="Enter Email"
                            name="email"
                            value={values?.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                          <small className="text-danger requiredTxt"> {touched?.email && errors?.email} </small>
                        </div>
                        <button className="signInBtn" type="submit"> Submit </button>
                      </React.Fragment>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};

export default ForgotPassword;
