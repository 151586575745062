import React from "react";
import { Route, Routes } from "react-router";
import NotFound from "../common/notFound/NotFound";
import ChangePassword from "../pages/auth/ChangePassword";
import EditProfile from "../pages/auth/EditProfile";
import Profile from "../pages/auth/Profile";
import AddEvent from "../pages/calender/AddEvent";
import Calendar from "../pages/calender/Calender";
import AddClient from "../pages/client/AddClient";
import Client from "../pages/client/Client";
import Details from "../pages/client/Details";
import AddEmployee from "../pages/employe/AddEmployee";
import EmployeDetails from "../pages/employe/EmployeDetails";
import Employee from "../pages/employe/Employee";
import AddTimeOff from "../pages/time-off/AddTimeOff";
import TimeOff from "../pages/time-off/TimeOff";
import Notifications from "../pages/notifications/Notifications";
import AdminPayout from "../pages/payout/AdminPayout";
import AddPayOut from "../pages/payout/AddPayOut";
import WebsiteContent from "../pages/websiteContent/WebsiteContent";

const AdminRoutes = () => {
  return (
    <Routes>
      {/***********************Client Management********************/}
      <Route path="/client" element={<Client />} />
      <Route path="/client/:id" element={<Details />} />
      <Route path="/client/add" element={<AddClient />} />

      {/***********************Employee Management********************/}
      <Route path="/employee" element={<Employee />} />
      <Route path="/employee/add" element={<AddEmployee />} />
      <Route path="/employee/:id" element={<EmployeDetails />} />

      {/*****************************Auth****************************/}
      <Route path="/profile" element={<Profile />} />
      <Route path="/edit-profile" element={<EditProfile />} />
      <Route path="/change-password" element={<ChangePassword />} />

      {/***************************Calendar**************************/}
      <Route path="/calendar" element={<Calendar />} />
      <Route path="/add-event" element={<AddEvent />} />

      {/*****************************Employee Leaves******************/}
      <Route path="/time-off" element={<TimeOff />} />
      <Route path="add-time-off" element={<AddTimeOff />} />

      {/*****************************Payout Routes ******************/}
      <Route path="/payout" element={<AdminPayout />} />
      <Route path="/add-timesheet" element={<AddPayOut />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/content" element={<WebsiteContent />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRoutes;
