import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { Link } from "react-router-dom";
import * as images from "../../assets/image";
import FilterModal from "../../common/FilterModal";
import useRole from "../../hooks/useRole";
import { downloadTimesheet, getEventWithPayouts, getPayouts, softDeletePayout, updatePayout } from "../../services/services";
import { constant } from "../../utils/constants";
import { getTableIndex, downloadFile } from "../../utils/function";
import Pagination from "../../utils/Pagination";
import { MdOutlineFileDownload } from "react-icons/md";
import PayoutModal from "../../common/PayoutModal";
import { useMutation } from "@tanstack/react-query";
import { toastAlert } from "../../utils/SweetAlert";
import { MdDeleteOutline } from "react-icons/md";
import Swal from "sweetalert2";

const AdminPayout = () => {
  const [search, setSearch] = useState();
  const [page, setPage] = useState(constant.PAGE_NO_ONE);
  const [filterShow, setFilterShow] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [selectedPayout, setSelectedPayout] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selected, setSelected] = useState([])

  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = () => {
    setPage(1)
    setIsToggled(!isToggled);
  };

  const role = useRole();

  const handleFilterShow = () => setFilterShow(true);
  const { data, refetch } = useQuery({
    queryKey: ["payout-list", page, filterData, isToggled],
    queryFn: async () => {
      let params = {
        page: page, limit: constant.PAGE_LIMIT, sortedBy: "startTime",
        sortBy: "ASC",
      };
      if (!isToggled) params.futureEvents = true
      else params.previousEvents = true
      if (
        Object.keys(filterData).length &&
        Object.keys(filterData).filter((item) => filterData[item]).length
      ) {
        setPage(1)
        params.range = `${filterData.start}TO${filterData.end}`;
        delete params.futureEvents
        delete params.previousEvents
      }

      // if (role !== constant.ROLE.EMPLOYEE) params.needEmployeeDetails = true;
      if (search) params.search = search;

      const resp = await getEventWithPayouts(params);
      return resp?.data?.data || {};
    },
  });

  const handleSampleDownload = async () => {
    try {
      let params = {
        sortedBy: "startTime",
        sortBy: "ASC",
      };
      if (
        Object.keys(filterData).length &&
        Object.keys(filterData).filter((item) => filterData[item])
      ) {
        params.range = `${filterData.start}TO${filterData.end}`;
      }
      if (search) params.search = search;
      let resp = await downloadTimesheet(params);
      let fileName = params?.range
        ? `${params?.range}_timesheet.xlsx`
        : "timesheet.xlsx";
      downloadFile(resp?.data, fileName);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleEditClick = (payout = null, time) => {
    if (!payout) return null
    setSelectedPayout({ ...payout, shiftDate: time });
    setShowEditModal(true);
  };
  const updateMutation = useMutation({
    mutationFn: ({ id, body }) => updatePayout(id, body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch()
    },
  });

  const toggleSelection = (payoutId = null) => {
    if (!payoutId) return;
    setSelected((prev) =>
      prev.includes(payoutId)
        ? prev.filter((id) => id !== payoutId)
        : [...prev, payoutId]
    );
  };
  const selectAll = (event) => {
    const { target: { checked } } = event
    if (!checked) return setSelected([]);

    const payoutIDs = []
    data.events.forEach((payoutEvents) => {
      if (moment(payoutEvents?.startTime).isSameOrBefore()) payoutIDs.push(payoutEvents._id)
    })
    setSelected(payoutIDs || [])
  }

  const handleSoftDelete = () => {
    if (!selected.length) {
      toastAlert('error', "No payout has been selected")
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: `You are going to delete ${selected.length} events `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (selected.length) {
          let body = { eventIds: selected };
          softDeleteMutation.mutate(body);
        }
      }
    });
  };

  const softDeleteMutation = useMutation({
    mutationFn: (body) => softDeletePayout(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch()
    },
  });
  return (
    <section className="main-content">
      <div className="commonSearchBar mt-3">
        <h4 className="heading20">Payout</h4>
        <div className="endContent">
          <div className="toggle-container">
            <div
              className={`toggle-switch ${isToggled ? "toggled" : ""}`}
              onClick={handleToggle}
            >
              <div className={`slider ${isToggled ? "toggled" : ""}`}></div>
            </div>
            <label className="label-text">Past Assignments</label>
          </div>
          <div className="clientSearch">
            <img
              src={images.Search}
              alt="searchImg"
              className="clientSearchIcon"
            />
            <input
              type="text"
              placeholder="Type and press enter to search."
              className="clientSearchInput"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) =>
                e.key == "Enter" && search.trim() !== "" && refetch()
              }
              onKeyUp={(e) =>
                e.target.value == "" && e.key == "Backspace" && refetch()
              }
            />
          </div>
          {role === constant.ROLE.ADMIN && (
            <React.Fragment>
              <div className="position-relative">
                {Object.keys(filterData).length &&
                  Object.keys(filterData).filter((item) => filterData[item])
                    .length ? (
                  <div className="redDot" />
                ) : (
                  ""
                )}
                <button
                  className="greenBtn calendar-btn"
                  type="button"
                  title="Filter By"
                  onClick={handleFilterShow}
                >
                  <CiFilter size={35} />
                </button>
              </div>
              <button
                className="greenBtn calendar-btn"
                type="button"
                title="Delete Selected"
                onClick={handleSoftDelete}
              >
                <MdDeleteOutline size={30} />
              </button>
              <button
                className="greenBtn calendar-btn"
                type="button"
                title="Download"
                onClick={handleSampleDownload}
              >
                <MdOutlineFileDownload size={30} />
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="commonTable">
                <thead className="tableHead">
                  <tr>
                    {role === constant.ROLE.ADMIN && <th> <input type="checkbox" onChange={selectAll} /></th>}
                    {role !== constant.ROLE.EMPLOYEE && <th>Employee Name</th>}
                    <th>Client Name</th>
                    <th>Assignment date</th>
                    <th>Check-In</th>
                    <th>Check-Out</th>
                    <th>Actual Check-In</th>
                    <th>Actual Check-Out</th>
                    <th>Client Initials</th>
                    <th>Note</th>
                    {role === constant.ROLE.ADMIN && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {data && data.events && data?.events?.length > 0 ? (
                    data?.events?.map((payout, index) => {
                      const employeeDetails = payout?.employeeDetails || null;
                      const payoutDetails = payout?.payoutDetails || null;
                      return (
                        <tr key={`payout_${index}`}>
                          {role === constant.ROLE.ADMIN &&
                            <td>
                              <input
                                type="checkbox"
                                checked={selected.includes(payout?._id)}
                                disabled={moment(payout?.startTime).isSameOrAfter()}
                                onChange={() =>
                                  toggleSelection(payout?._id)
                                }
                              />
                            </td>
                          }
                          {role !== constant.ROLE.EMPLOYEE && (
                            <td className="text-capitalize">
                              {employeeDetails
                                ? `${employeeDetails?.firstName || ""} ${employeeDetails?.lastName || ""
                                }`
                                : "Employee not available"}
                            </td>
                          )}
                          <td className="text-capitalize">
                            {payout?.clientDetails ? `${payout?.clientDetails?.firstName || ""} ${payout?.clientDetails?.lastName || ""}` : "Client not available"}
                          </td>
                          <td> {moment(payout?.startTime).format("ll")}</td>
                          <td> {moment(payout?.startTime).format("hh:mm A")}</td>
                          <td> {moment(payout?.endTime).format("hh:mm A")} </td>
                          <td> {payoutDetails ? moment(payoutDetails.checkIn, "HH:mm").format("hh:mm A") : "Not Set"} </td>
                          <td> {payoutDetails ? moment(payoutDetails.checkOut, "HH:mm").format("hh:mm A") : "Not Set"} </td>
                          <td className="sign-area">
                            {payoutDetails?.signature ? (<img src={payoutDetails?.signature} className="digital-sign" alt="Signature" />) : ("N/A")}
                          </td>
                          <td> {payoutDetails && payoutDetails.note && payoutDetails?.note?.trim ? payoutDetails?.note : "N/A"} </td>
                          {/* <td> {payout?.note?.trim() !== "" ? payout?.note : "N/A"} </td> */}
                          {role === constant.ROLE.ADMIN && (
                            <td>
                              {payoutDetails ? <img
                                src={images.edit}
                                alt="Edit"
                                className="cursor editImg"
                                onClick={() => handleEditClick(payoutDetails, payout.startTime)}
                              /> : "N/A"}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={11}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={data?.total_pages}
              />
            </div>
          </div>
        </div>
      </div>
      {showEditModal && (
        <PayoutModal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
          payoutData={selectedPayout}
          onSave={(updatedPayout) => {
            console.log(updatedPayout, selectedPayout?._id);

            updateMutation.mutate({ id: selectedPayout?._id, body: updatedPayout })
            setShowEditModal(false);
          }}
        />
      )}

      {filterShow && (
        <FilterModal
          show={filterShow}
          setShow={setFilterShow}
          refetch={refetch}
          data={filterData}
          setData={setFilterData}
        />
      )}
    </section>
  );
};

export default AdminPayout;
