import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NavigateBack from "../../common/NavigateBack";
import { getUserDetails } from "../../services/services";

const Details = () => {
  const { id } = useParams();
  const { data } = useQuery({
    queryKey: ["client-details", id],
    queryFn: async () => {
      const resp = id && (await getUserDetails(id));
      return resp?.data?.data ?? {};
    },
  });

  return (
    <>  
      <section className="main-content">
        <NavigateBack>Client Details</NavigateBack>
        <Card border="light" bg="light`" className="p-4">
          <div className="clientDetails">
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Client Name</h6>
              <span className="heading16 ps-2 text-capitalize">{`${data?.firstName} ${data?.lastName}`}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Date of birth</h6>
              <span className="heading16 ps-2 text-capitalize">{data?.dob ? `${moment(data?.dob).format("ll")}` : "Not set"}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Email</h6>
              <span className="heading16 ps-2">{data?.email}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Phone Number</h6>
              <span className="heading16 ps-2">{data?.phoneNumber}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Address</h6>
              <span className="heading16 ps-2">{`${data?.userAddress?.address || ""} ${data?.userAddress?.city || ""} ${data?.userAddress?.state || ""} ${data?.userAddress?.country || ""} (${data?.userAddress?.zipCode || ""})`}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Note</h6>
              <span className="heading16 ps-2">
                {`${data?.note || "Not set"}`}
              </span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Authorized Employees</h6>
              <span className="heading16 ps-2">
                {data?.authorizedDetails?.length
                  ? data?.authorizedDetails?.map((i) => `${i.firstName}  ${i.lastName}`)?.join(", ")
                  : "N/A"}
              </span>
            </div>

            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Created At</h6>
              <span className="heading16 ps-2">
                {moment(data?.createdAt).format("lll")}
              </span>
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};

export default Details;
